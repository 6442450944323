body{
  overflow-x: hidden;
}
.t-text{ 
  color: #4b9e70 !important;
  text-decoration: none !important;
}
.t-back-round{
  display: flex;
  text-align: center;
  align-self: center;
  background-color: #4b9e70 !important;
  border-radius: 100px;
  color: #fff !important;
  padding: 8px;
}

.width-wfa{
  width: -webkit-fill-available;
}
.form-control{
  border: 1px solid #DDDDDD !important;
}
.form-control-web{
  border: none;
}
.a-link{
  color: blue;
}
.fg-1{
  flex-grow: 1;
}

.sidebar .nav p, .off-canvas-sidebar .nav p{
  font-size: medium;
}

.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper{
  overflow-x: hidden !important;
}

.cp{
  cursor: pointer;
}
.op8:hover  {
  opacity: .8;
}

.desc{
 color: red; 
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.mt-10{
  margin-top: 10px;
}
.btn-primary {
  background-color: #4b9e70 !important;
}
.btn-large{
  font-size: 20px !important;
  padding: 20px !important;
  text-transform: capitalize !important;
  width: 200px;
}
.icon-sm{
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-size: cover;
  font-size: 30px;
}

.icon-md{
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background-size: cover;
  font-size: 30px;
}
.df{
  display: flex;
}
.dnone{
  display: none;
}
.jcc{
  justify-content: center;
}
.jcsb{
  justify-content: space-between;
}
.jcfe{
  justify-content: flex-end;
}
.jcsa{
  justify-content: space-around;
}
.aic{
  align-items: center;
}
.autocomplete-dropdown-container{
  position: absolute;
  cursor: pointer;
  z-index: 2;
  background-color: #ffff;
  width: 100%;
}
.fd-c{
  flex-direction: column;
}
.form-required{
  font-size: 15px;
  color: #bb2222;
}
.ta{
  text-align: center;
}

.t-large{
  font-size: 30px !important;
}
.t-medium{
  font-size: 20px !important;
}
.t-small{
 font-size: 10px !important; 
}

.no-border{
  border: none !important;
}

/* dashboard common class */
.dashboard-card{
  max-height: 31.5rem;
}

.p-10{
  padding: 10px;
}

.m-card{
    border-radius: 5px;
    box-shadow: 2px 2px 9px 2px #CFCFCF;
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
    border: 2px solid #f9f9f9;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    max-width: 550px;
}