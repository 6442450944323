* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
.btn,
.navbar .navbar-nav > a.btn >p{
  text-transform: lowercase !important;
}
.navbar.navbar-absolute{
  z-index: 0 !important;
}
.dropdown-menu .dropdown-item a{
  color: #333;
}
.dropdown-menu .dropdown-item:hover > a {
  color: #fff !important;
}
section.okkji-faq.offer-mobile {
  display: none;
}
.ant-menu-inline .ant-menu-submenu{
  padding: 0px !important;
}
.ant-menu-inline .ant-menu-submenu .nav-link{
  padding: 0px !important;
}
.ant-menu{
  background: #eee !important;
}
.sidebar .sidebar-wrapper li.active>a:not([data-toggle=collapse]):after {
  border-right: none !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #4b9e70 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > i{
  color: #fff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a{
  color: #fff !important;
}
.ant-menu-inline .ant-menu-item::after{
  border: none !important;
}
.sidebar .sidebar-wrapper li.active>a:not([data-toggle=collapse])::before {
  border-right: none !important;
}
.ant-menu-submenu .ant-menu-submenu-inline .nav-link {
  background-color: rgb(238, 238, 238);
}
.ant-menu-submenu > .ant-menu{
 background-color: rgb(238, 238, 238) !important; 
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item{
  display: flex;
  align-items: center;
  padding-left: 10px !important;
}
.sidebar .nav li > a{
  margin: 2px 0px !important;
  color: #777 !important;
}
.sidebar .nav i{
  color: #777 !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child){
  margin-bottom: 0px !important;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  padding-left: 10px !important;
  line-height: 30px !important; 
}
.navbar .navbar-collapse .nav-item a {
    font-size: 14px !important;
}
.row.text-center.mobile {
  display: none;
}

p {
  font-size: 1.25rem;
  color: #5e5e5e;
  margin-top: 0;
  margin-bottom: 2rem;
}

h1 {
  font-size: 2.1rem;
  font-weight: 500;
}

li p {
  font-size: 1rem;
  color: #5e5e5e;
}

img {
  vertical-align: middle;
}

body {
  background-color: white !important;
  position: relative;

  font-family: Muli, BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif !important;
  font-size: 14px;
  color: #5e5e5e !important;
}
.main-panel{
  width: calc(100% - 220px) !important;
  background-color: #fdfeff;
  position: relative !important;
}
.main-panel > .content{
  padding: 0px 20px !important;
  margin-top: 90px !important;
  min-height: 75vh;
}
.customer-filter p {
    font-size: 17px;
    color: #5e5e5e;
    margin: 8px !important;
}
.navbar a:not(.btn):not(.dropdown-item) {
    color: #333 !important;
}
/*.col-sm-7.figure-img1:before{
  content: "";
    display: block;
    background: url(/static/media/Asset6.1f22c602.png) 0 -2px no-repeat;
    background-size: 100% 100% !important;
    width: 87.5% !important;
    height: 557px !important;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.col-sm-7.lesspadding{
  padding-right: 0 !important;
}
*/
.wallet-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.footer {
    padding: 24px 0;
    left: 0 !important;
    bottom: 0;
    width: 100%;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.footer .credits {
  margin: 0 auto;
}
.dashbord-card1 {
  height: 150px !important;
}
.dashbord-card2 {
  height: 150px !important;
}
.dashbord-card3 {
  height: 150px !important;
}
.dashbord-card4 {
  height: 150px !important;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    font-size: 24px;
    float: left;
    margin-left: 0px !important;
    margin-right: 5px !important;
    line-height: 30px;
    width: 34px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
}
.fixed-top.scrolled .nav-link {
  color: #555;
}

.navbar-brand img {
  position: absolute;
  margin-top: -17px !important;
}

#header .navbar-brand .logo {
  opacity: 1;
}

#header .navbar-brand .logowhite {
  opacity: 0;
}

.sticky-header-active #header .header-logo .logo-normal {
  opacity: 0;
}

.sticky-header-active #header .header-logo .logo-sticky {
  opacity: 1;
}

.navbar {
  padding: 5px;
  font-weight: 300;
  box-shadow: none !important;
}

/*.bg-image{
    background-image: url(../src/img/back.png);
  
    min-height: 50px;
    left:0 ;
    right:0;
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    position: relative;
  
} */

nav#parallelogram {
  background-color: #fdfffe !important;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18) !important; */
}

.navbar.navbar-dark .navbar-toggler-icon {
  background-color: #259d70;
}

.okjii-offer-image {
  top: -49px !important;
  z-index: -1;
}

.row-addding {
  padding-top: 3rem;
}

.navbar .navbar-toggler {
  width: 0px !important;
}

.navbar-toggler {
  padding: 0.25rem 15px !important;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  margin-left: 2rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-nav > li {
  list-style-type: none;
  padding-right: 2.8rem;
  margin: 0;
  flex-direction: row;
  justify-content: space-around;
  font-size: 0.85rem !important;
  font-weight: 300px !important;
  flex-wrap: nowrap;
  word-break: normal;
  white-space: nowrap;
}
.navbar-nav > li a {
  color: #5e5e5e !important;
  font-weight: 400 !important;
}

.badge.badge-text {
  margin: 0;
  font-size: 70%;
  padding: 0.1em 0.4em;
  color: #8d8282 !important;
  font-weight: 400;
  text-transform: uppercase;
}
a sup {
  top: -0.8em !important;
}

a {
  white-space: nowrap;
}

.home-page-order {
}
.okjii-mainbody {
  background-image: url(../src/img/home/Background-images.png);
  top: 0;
  left: 0;
  right: 0;
  margin-top: 2%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.okkjii-customer-mt {
  margin-top: -12%;
}
.navbar .navbar-nav .nav-link {
    text-transform: initial !important;
    font-size: 0.7142em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
    margin-right: 3px;
}
.search-page-faq .search-form {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.OkjiiRetailerBody {
  background-image: url(../src/img/retailer/BackgroundRetailer.png);
  top: 0;
  left: 0;
  right: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.okkjiicontactus {
  padding-top: 13rem !important;
  background-image: url(../src/img/contact/Contactusdesktop.png);
  top: 0;
  left: 0;
  right: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.okkjiicontactus h1 {
  margin-top: -5rem;
  padding-left: 2rem;
  font-size: 18pt;
  font-weight: 600;
  columns: #5e5e5e;
}
.about-us-page .getapp-link .input-group-lg>.form-control:not(textarea) {
    height: calc(1em + 3rem + 2px)!important;
    background-color: #E9ECEF!important;
}
.about-us-page .getapp-link .input-group {
    background-color: #E9ECEF !important;
    border-radius: 5px !important;
}
.okkjiicontactus textarea.form-control {
  padding-left: 15px;
}

.OkjiiRetailerBody h1 {
  margin-top: 1rem;
}

.okkji-fast-body {
  background-image: url(../src/img/okjiifast/OkkjifastBackground.png);

  top: 50px;
  left: 0;
  right: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.okjii-offer-body {
  background-image: url(../src/img/offer/BAckgroundOfferBody.png);

  top: 56px;
  left: 0;
  right: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.main-body-mobile {
  display: none;
}

.container-fluid.OkjiiRetailerBody {
  display: block;
}

.OkjiiRetailerBody h1 {
  padding-top: 11rem;
}
.container-fluid.OkjiiRetailerBody-mobile {
  display: none;
}
.okkjiicontactus-mobile {
  display: none;
}

section.okkji-faq.faq-mobile,
.offer-mobile {
  display: none;
}
.mobile-number-mt {
  margin-top: -25%;
}

.okjii-mainbody-mobile {
  background-image: linear-gradient(
      to right,
      rgb(234, 236, 234, 0.6),
      rgb(234, 236, 234, 0.6)
    ),
    url(../src/img/home/Background-images.png);

  top: 50px !important;
  left: 0;
  right: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.mobile-google {
  margin-left: 18%;
}

.okjii-install-image {
  float: center !important;
}

.image-position .Asset6 {
  width: 80% !important;
  margin-top: 2rem;
}
.image-position .RetailerHome {
  width: 45% !important;
  margin-top: -1rem;
}
.image-position .CustomerImage {
  width: 10% !important;
}
.card-chart .card .card-header {
    border-bottom: 1px solid #DCDCDC !important;
}


.home-order-details{
    left: 25%;
    top: -30%;
    position: inherit;
  }
  .total-order-amount{
    text-align: center;
    background: #fff;
    padding: 10px 0px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px #DCDCDC;
}
.home-order-details p {
    padding-bottom: 8px !important;
    margin-bottom: 0px;
}
.order-color {
  color: #EC4707 !important;
  font-size: 1.1rem !important;
    font-weight: 600;
}
.order-amount-cl {
  color: #37A67E !important;
  font-size: 1.1rem !important;
    font-weight: 600;
}
.left-order-border {
  border-left: 1px solid #9A9A9A;
}
.recent-order-list {
    position: inherit;
    top: 40px;

}
.order-list-items {
    position: inherit;
    top: 10px;
    background: #fff;
    padding: 8px 0px 8px 10px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px #DCDCDC;
}
.order-mt1 {
  margin-top: 5%;
}
.order-list-items p{
    margin-top: 9px;
    font-size: 14px !important;
    margin-left: 15px;
}
.order-list-items p span {
  color: #0F955F !important;
  font-weight: 600;
}
.order-list-items .pright {
  text-align: right;
  margin-left: 40px;
}
.image-position h1 {
  margin-top: 3rem;
}
.image-row {
  display: flex;
  justify-content: flex-end;
}
.marketing-iframe {
  width: 500px; 
  height: 300px;
}
.okjii-explore {
  margin-left: 10% !important;
}
/*.okkjii-mt {
  margin-top: 47%
}*/
.okjii-explore h1 {
  font-weight: 600;
  color: #5e5e5e !important;
  font-size: 14pt;
  /* padding-top: 0rem !important; */
}

.okjii-explore h2 {
  padding-bottom: 2rem !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  color: #5e5e5e !important;
  letter-spacing: 0.3px;
}

.okjii-explore p {
  color: #5e5e5e;
  font-size: 1rem;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
}

.install-now,
p.install-now {
  margin-top: 3.5rem !important;
  font-size: 1.2rem !important;
  align-items: center !important;
  margin-bottom: 0 !important;
  font-weight: 700 !important;
}
.card-chart .card-header .card-title {
    margin-top: 10px;
    margin-bottom: 0.75rem !important;
}
.card-chart .card-header .card-category {
    border-bottom: 1px solid #DCDCDC !important;
    padding-bottom: 8px;
}
.okjii-install-image {
  float: left;
}

.okjii-install img {
  width: 6% !important;
  padding-top: 7px !important;
  padding-bottom: 1rem;
  align-content: center !important;
}

.apple {
  height: 60px !important;
}

.google {
  height: 60px !important;
}

.playstore {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.form-input {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: #ededed !important;
  height: 55px;
  border-radius: 10px;
}

.input-item {
  justify-content: center;
  align-content: center;
}

.form-input input {
  background-color: #ededed !important;
  border-top: none !important;
  border-left: none !important;
  padding-left: 0px !important;
}

.form-input button {
  margin: 0rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  color: #fff;
  background-color: #259a70;
  border: 0;
  border-radius: 5%;
  padding: 0.25rem 1.14rem;
  font-size: 0.81rem;
}

.input-group.box-input.form-control {
  background-color: red !important;
}

.input-group.box-input > .input-group-append > .input-group-text {
  background-color: #259d70 !important;
}

.okjii-explore-left {
  padding-left: 3rem !important;
}

/*  okjii-daily*/

.okjii-best h1 {
  padding-top: 6rem !important;
  color: #5e5e5e;
  font-size: 1.2rem !important;
  padding-bottom: 1.6rem !important;
}
.okjii-best b.best {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #5e5e5e;
}
.okjii-daily {
  background-color: #f7f7f7 !important;
  padding-bottom: 20px;
  padding-top: 5px;
}

.okjii-daily h1 {
  padding-top: 4rem !important;
  color: #2a2aff !important;
  font-size: 1.2rem !important;
  padding-bottom: 1rem;
}

.okjii-daily b.daily {
  font-size: 1.2rem !important;
  font-weight: 600;
  color: #2a2aff !important;
}
.okjii-daily p {
  color: #5e5e5e !important;
  font-size: 1rem !important;
  padding-bottom: 0.5rem !important;
  letter-spacing: 0.5px;
  text-align: justify;
}

ul.list-unstyle {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 0 !important;
}

.list-unstyle li {
  font-size: 1rem !important;
  line-height: 1.9em !important;
  color: #5e5e5e;
  color: #5e5e5e;
  letter-spacing: 0.1px;
}

.fa-2x {
  font-size: 1em !important;
}
figure.daily-figure-img {
  width: 66%;
}

/* okjii-homedelivery */

.okjii-homedelivery h1 {
  color: #2a2aff !important;
  padding-top: 2rem !important;
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
}

.okjii-homedelivery b.home {
  font-size: 1.2rem !important;
  font-weight: 600;
  color: #2a2aff !important;
}

.okjii-homedelivery p {
  color: #5e5e5e !important;
  font-size: 1rem !important;
  padding-bottom: 0.5rem !important;
  letter-spacing: 0.5px;
  text-align: justify;
}
.home-delivery-figure-img {
  width: 90% !important;
}

.okjii-create-store-img {
  width: 65% !important;
}

/* Khata Book */

.okjii-khatabook1 {
  background-color: #3ba778 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  text-align: justify;
}

section.okjii-khatabook {
  padding-top: 2rem;
  margin-left: 25px;
}

.okjii-khatabook1 h1 {
  padding-top: 2rem !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
  color: #fff;
}

.okjii-khatabook1 p {
  color: #fff !important;
  font-size: 1rem !important;
  padding-bottom: 0.5rem !important;
  letter-spacing: 0.5px;
}

.list-unstyle.list-color li {
  font-size: 1rem !important;
  line-height: 1.9em !important;
  color: #fff;
  letter-spacing: 0.1px;
}
.no-break {
  word-break: keep-all;
}

/* home-testimonial  */
.home-testimonial {
  padding: 0 50px !important;
}

.home-testimonial h1 {
  color: #5e5e5e !important;
  padding-top: 20% !important;
  font-weight: 600;
  font-size: 1.2rem !important;
  padding-bottom: 0.3rem;
}

.home-testimonial h2 {
  color: #5e5e5e;
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem !important;
  padding-top: 6px;
}

.home-testimonial p {
  font-size: 1rem !important;
}

.testmonial-slide h2 {
  color: #5e5e5e !important;
  padding-top: 1.8rem;
  font-size: 1.2rem !important;
  font-weight: 500;
  padding-bottom: 0 !important;
}
.testmonial-slide p {
  padding-top: 0 !important;
}

i.fa.fa-quote-left.color-green {
  color: #259d70 !important;
}

.Asset12 {
  width: 5% !important;
}

.carousel.carousel-fade.z-depth-1.testmonial-slide {
  box-shadow: none !important;
}

.carousel-inner {
  display: flex;
  align-content: center !important;
  justify-content: space-between;
  flex-wrap: wrap !important;
  margin-bottom: 4px;
}

.testmonial-slide.carousel-fade a.carousel-control-prev {
  background-color: #fff;
  position: absolute;
  width: 6%;
  height: 11%;
  padding-top: 5px;
  border-radius: 50%;
  transform: translatey(-100%);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  margin-top: 6.1% !important;
}

.testmonial-slide.carousel-fade a.carousel-control-next {
  position: absolute;
  background-color: #fff;
  width: 6%;
  transform: translatey(-100%);
  height: 11%;
  left: 7% !important;
  padding-top: 5px;
  color: #000 !important;
  border-radius: 50%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  margin-top: 6.1% !important;
}

.carousel .carousel-control-next-icon {
  background-image: url(../src/img/chevronright.png) !important;
}
.carousel .carousel-control-prev-icon {
  background-image: url(../src/img/chevronleft.png) !important;
}

.home-testimonial {
  position: relative;
}

.testimoni-img {
  /* display: flex; */
}

.testimoni-img > .author-img {
  /* flex:0; */
}

img.rounded-circle.testimoni-img {
  margin-left: 0 !important;
  /* margin-right: 28px !important; */
}

.home-testimonial.card {
  font-weight: 300;
  border: 0;
  box-shadow: none !important;
}

.home-testimonial blockquote p {
  font-size: 1rem !important;
  letter-spacing: 0.5px;
  text-align: justify !important;
}

.turested-slide {
  display: flex;
  flex-wrap: wrap;
  align-content: left;
}

.h1-trusted h1 {
  color: #5e5e5e !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  padding-bottom: 2rem;
}

footer.page-footer,
.footer-copyright {
  background-color: #f7f7f7 !important;

  color: #5e5e5e !important;
  border-top: 1px solid #cecece !important;
}

footer.page-footer h5 {
  font-size: 1.2rem !important;
  padding-bottom: 1.4rem !important;
}

footer.page-footer a {
  color: #5e5e5e !important;
  font-size: 1rem;

  font-size: 1rem !important;
  font-weight: 300 !important;
}

.container-fluid.footer-margin {
      margin: 1.8rem 0rem 1.8rem 0rem;
}
.d-flex.align-content-stretch.flex-wrap.example-parent img {
  width: 90% !important;
}

.list-unstyled li a {
  font-size: 0.8rem !important;
}
h4.payment {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  padding-bottom: 0.5rem !important;
}
.list-unstyled.footer-list li {
  padding-bottom: 0.3rem !important;
}

footer.page-footer {
  border-bottom: 1px solid #cecece !important;
  padding-left: 15px; 
  padding-right: 5px;
}
.footer-bdr-right {
  border-right: 1px solid #cecece !important;
}

.footer-bdr-right h5,
.bdr-font h5 {
  font-size: 1.5rem;
  font-weight: 400;
}
.example-parent {
  padding-right: 35px;
}

.example-parent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.example-parent img {
  width: 52% !important;
}
p.cite-text {
  font-size: 90% !important;
  font-weight: 300 !important;
  letter-spacing: 0.2px;
}

.col-example a {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.figure-img > img {
  margin-bottom: 0.5rem;
  line-height: 1;
  padding-left: 64px !important;
  width: 136% !important;
}

.col-lg-5.text-center {
  margin-left: 25% !important;
}

.list-image-checkbox li:before,
.list-image-hand li:before,
.list-image-star li:before,
.list-image-correct li:before,
.list-image-incorrect li:before,
.list-image-checkmark li:before,
.list-image-checkmark-white li:before {
  content: "";
  background-size: 70% 70%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  left: -1.5em;
  top: -0.2em;
  height: 1.5em;
  width: 1.5em;
}

.daily-use div h1 {
  margin-top: 5rem !important;
}

section.daily-use {
  background-color: cornsilk !important;
}

/*.list-image-checkbox > li:before {
  background-image: url(../../mhe.mhhe-blk2/img/list-icons/icon-checkbox.svg);
}*/
/*---Step List---*/
.list-step,
.list-step-center {
  list-style-type: none;
  display: table;
}

.list-step table,
.list-step-center table {
  width: 100%;
}

.list-step li,
.list-step-center li {
  display: table-row;
  font-family: "Proxima Nova Regular", Verdana, Helvetica, sans-serif;
  margin-bottom: 0.5em;
}

.list-step .step-title,
.list-step-center .step-title {
  display: table-cell;
  padding-right: 1.5em;
  white-space: nowrap;
}

.list-step .step-info,
.list-step-center .step-info {
  display: table-cell;
  word-break: break-word;
}

.list-step .step-info ol,
.list-step-center .step-info ol,
.list-step .step-info ul,
.list-step-center .step-info ul {
  font-style: normal;
  margin-left: 0.6667em;
}

/* testimony */

/* Trusted */
.h1-trusted {
  font-size: 3rem !important;
  font-weight: 500 !important;
  color: #5e5e5e !important;
}

/* carousel-item */
.carousel-item.active.carousel-slide-item.turested-slide {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
  width: auto;
}

ol.carousel-indicators li {
  background-color: #c6c6c6 !important;
  padding-top: 2%;
  transform: translatey(80px);
}

.carousel-item img {
  margin: 20px !important;
}

/*.controls-top {
  transform: translatey(599px) !important;
}*/

/* carusoul end*/

.trusted-icon {
  width: 50% !important;
}

.card-shadow {
  box-shadow: none !important;
}

/*   Okjii retalor CSS Start*/

/* okjii-contact*/

.okjii-contact {
  background-color: #ededed !important;
}

.okkjii-retalore b.best {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #5e5e5e;
}
.okkjii-retalore h1 {
  font-size: 1.2rem !important;
}
.input-group .input-group-prepend .input-group-text {
  padding-right: 15px !important;
}

.okjii-retaler h1 {
  font-weight: 600;
  font-size: 17pt !important;
  color: #5e5e5e;
  padding-top: 6rem;

  padding-bottom: 1rem;
}

.okjii-retaler h1 span.text-green {
  color: #259d70;
}

.contact-form {
  padding-left: 2rem;
}

.okjii-contact input {
  padding: 1rem;
}

.okjii-contact img {
  padding-bottom: 0.4rem;
}
.appIcon-row {
    display: block;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: center;
}
.appIcon {
  margin: 10px;
  width: 50px; 
  height: 50px;
}
.google-map-right {
  float: right;
  transform: translate(37%, -107%);
}
.google-map-right img {
  width: 55% !important;
}
.container-fluid.okkjii-retalore {
  padding-top: 3rem;
}
.text-normal {
  font-weight: 400;
}
.okjii-create-store-background {
  background-color: #f7f7f7 !important;
  padding-top: 3rem;
  padding-bottom: 11px;
  z-index: 1;
}
.okjii-create-store-img {
  width: 57% !important;
}

.okjii-create-store {
  background-image: url(../src/img/retailer/createshop1.png);
  background-repeat: no-repeat !important;
}

.okjii-create-store h1 {
  color: #2a2aff !important;
  padding-top: 2rem !important;
  /* font-weight: 400 !important; */
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
}
.okjii-create-store b.create {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.okjii-create-store p {
  color: #5e5e5e !important;
  font-size: 1rem !important;
  text-align: justify;
  letter-spacing: 0.5px;
}

.retailer-figure-img2,
.okjii-fast-img4,
.faqimg01,
.okjii-fast-ourpractice {
  width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  top: -16px;
  z-index: -1;
}
.okjii-offer-image {
  width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  top: -16px;
  z-index: -1;
}

.home-delivery-figure-img {
  padding-top: 4.5rem;
  padding-bottom: 3rem;
}

.okjii-sell-online {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.okjii-sell-online h1 {
  color: #2a2aff !important;
  padding-top: 2.5rem !important;
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
}

.sell-online-figure-img {
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 85%;
}

.sell {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.okjii-sell-online p {
  color: #5e5e5e !important;
  font-size: 1rem !important;
  letter-spacing: 0.5;
  text-align: justify;
}

.okjii-contact button {
  background-color: #259d70 !important;
  border-radius: 4px;
  border: none !important;
  color: #fff !important;
  width: auto !important;
  transform: translate(255% 30%);
}

.trusted-by-store {
  display: flex;
  flex-direction: column;
}

.okjii-whyweshop,
.okjii-customerkirana {
  background-color: #f7f7f7 !important;
}

.okjii-whyweshop section {
  display: flex;
  flex-direction: column;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.okjii-explore-left {
  margin-left: 5%;
  margin-top: 2%;
}
.home-video {
  margin-left: 25%
}
.home-video video{
  width: 60%;
  margin-top: 18%;
}
.h1, h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 15px !important;
}
.container-fluid {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
}
.getapplink-ml {
  margin-left: 15%;
}
.okkjii-home-bg {
  background: #EAECEA;
}

.okjii-whyweshop section h1 {
  font-size: 1.2rem !important;
  padding-bottom: 0.5rem;
  color: #5e5e5e;
}

.okjii-whyweshop section h2 {
  color: #5e5e5e;
  font-size: 1.1rem;
  padding-bottom: 2.5rem;
}
.figure-whayweshop img {
  width: 85%;
}

.okjii-fast-img {
  margin-left: 0 !important;
  margin-right: -15px !important;
}

.okjii-fast {
  position: relative;
  text-align: left;
  color: #122538;
}

.okjii-fastcon {
  position: absolute;
  top: 65px;
  left: 53px;
}

.okjii-fastcon h1 {
  font-size: 16pt !important;
  font-weight: 600;
  color: #fff;
  padding-top: 1.5rem;
  line-height: 1.8rem;
}

.okjii-fastcon h2 {
  font-size: 1.2rem !important;
  margin-top: 0rem;
  font-weight: 400;
}

.okjii-fasthow h1 {
  font-weight: 600;
  color: #5e5e5e !important;
  font-size: 17pt;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.okjii-fastfast h1 {
  font-weight: 500;
  color: #5e5e5e !important;
  font-size: 22pt;
  text-align: center;
  padding-top: 30px;
}

.okjii-fast {
  font-size: 17pt !important;
  font-weight: 600 !important;
  color: #5e5e5e !important;
}
.okjii-fast-img-how {
  width: 60%;
  align-items: center !important;
}
.okkji-howwework h1 {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #5e5e5e;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.okjii-fastheading {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #5e5e5e;
  padding-top: 3rem;
  padding-bottom: 0rem;
}

.okjii-rapid img {
  width: 75%;
}

.okkji-howwework {
  margin-top: -14rem;
  background-color: #fff !important;
}

.okkji-howwework img {
  width: 30%;
}

.okkji-howwework {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.faqimg05,
.faqimg07 {
  width: 50% !important;
}

figcaption h4 {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: #5e5e5e !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.okkji-offer {
  position: relative;
  display: flex;
  align-content: left !important;
  justify-content: left !important;
  z-index: 1;
}

.about-let-us-container {
  display: flex;
  flex-direction: column;
}

.okkji-faq,
.okkji-about {
  position: relative;
  display: flex;
  align-content: center !important;
  justify-content: center !important;
  z-index: 1;
}

.faq-h1 {
  font-size: 1.7rem;
  font-weight: 500;
  color: #5e5e5e;
  text-align: center;
  display: block;
  padding-top: 1rem;
}

.search-page {
  position: absolute;
  width: 100%;
  top: 25%;
  display: flex;
  justify-content: center !important;
}

.input-group.md-form.form-sm.form-1.pl-0 {
  background-color: #ffff !important;
}

.search-form .input-group-prepend {
  margin-right: 10px !important;
}
.input-group-append {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-page h1 {
  font-size: 17pt;
  font-weight: 700;
  color: #fff;
  padding-bottom: 1rem;
}

.search-form {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 10px !important;
}

.accordion .accordion-item.active .title:before {
  content: url(../src/img/faq/faqimg08.png) !important;
  width: 4% !important;
  height: 4% !important;
  margin-right: 10px;
}

.accordion .accordion-item .title:before {
  content: url(../src/img/faq/faqimg06.png) !important;
  width: 4% !important;
  height: 4% !important;
  margin-right: 10px;
}

.accordion {
  border: none !important;
}

.accordion .accordion-item .panel {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  border-bottom: none !important;
}

.accordion .accordion-item .panel {
  border-left: 1px solid #5e5e5e !important;
  border-right: 1px solid #5e5e5e !important;
  border-bottom: 1px solid #5e5e5e !important;
  border-radius: 0px 0 5px 5px;
}

.accordion .accordion-item .panel p {
  font-size: 1rem !important;
}
.accordion .accordion-item.active .title {
  background-color: #fff;
}

.accordion-item {
  padding-bottom: 5% !important;
}
.accordion .accordion-item .title {
  background-color: #fff !important;
  border: 1px solid #5e5e5e !important;
  color: #5e5e5e !important;
  font-size: 1rem !important;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  font-weight: 400 !important;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.support-para {
  font-size: 1rem !important;
  color: #5e5e5e;
  font-weight: 400 !important;
}

.text-blue {
  color: blue;
}

.okjii-account h3 {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: #5e5e5e !important;
  padding-bottom: 1rem;
}

.faq-card {
  margin-bottom: 1rem !important;
}

.faq-card img {
  margin-right: 0.5rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.faq-card p {
  font-size: 1rem !important;
  color: #5e5e5e !important;
}

.white {
  background-color: #fff !important;
  border-radius: 7px !important;
}

/*  offfer page Css*/
.input-group-text {
  border: none !important;
  font-weight: 700 !important;
}

.input-group-text::placeholder {
  font-size: 0.2rem !important;
}
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 3rem + 2px) !important;
  background-color: #e9ecef;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-control {
  border: none !important;
}

.btn-success.btn-subscribe {
  background-color: #259d70 !important;
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0.54rem 1.14rem;
}

/* Offer card ***/
.okjii-offer h1 {
  font-size: 17pt !important;
  font-weight: 500 !important;
  padding-top: 10rem;
  color: #5e5e5e;
  padding-left: 2rem;
}

.okjii-offer h2 {
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  padding-bottom: 3rem;
  padding-left: 2rem;
  color: #5e5e5e;
}
.okjii-bring {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  padding-top: 0rem;
  padding-bottom: 1.5rem;
  color: #5e5e5e;
}

/* .input-form{
  padding-left:2rem;
  padding-right:2rem;
} */
.input-form .input-group {
  background-color: #e9ecef !important;
  border-radius: 5px !important;
}

.input-form .input-group-lg > .form-control:not(textarea) {
  /* height: calc(1em + 1rem + 2px) !important; */
  background-color: #e9ecef;
  border: none !important;
}

.input-form .btn-success.btn-subscribe {
  font-size: 0.8rem !important;
  font-weight: 500;
}

.offer-h1 {
  font-size: 2.5rem !important;
  font-weight: 500 !important;
}

.offer-h1 span {
  color: #259d70 !important;
  font-size: 2.5rem !important;
}
.about-mobile-view-image  {
  display: none;
}
.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid silver;
  display: flex;
  justify-content: center;
  border: none;
}

.wrap {
  flex-wrap: wrap;
}

.img-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.img-flex img {
  height: 30px;
}

.flex-item {
  padding: 5px;
  margin: 10px;
  color: black;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.card-content {
  border: 1px solid black !important;
  padding: 10px !important;
  width: 240px !important;
  height: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card-content h3 {
  font-size: 1.1rem !important;
  padding: 5px !important;
  font-weight: 600 !important;
  padding-top: 35px !important;
}

.card-content h3.paytm {
  color: #06306f !important;
  padding-bottom: 20px !important;
}
.card-content h3 span.text-paytm {
  color: #02b9ef !important;
}

.card-content h3.easyday {
  color: #8bc640 !important;
  padding-bottom: 20px !important;
}
.card-content h3 span.text-easyday {
  color: #fdbe3e !important;
}

.card-content h3 span.text-easyday-price {
  color: #259a70 !important;
}

.card-content h3.visa {
  color: #0e1a63 !important;
}
.card-content h3 span.text-visa {
  color: #002898 !important;
}

.card-content h3 span.text-visa-price {
  color: #1648b7 !important;
}

.offer-card .btn {
  font-size: 0.4em !important;
}

.btn-paytm {
  background-color: #c9f0fb !important;
  color: #06306f !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
  align-items: flex-end !important;
}

.btn-easyday {
  background-color: #fef0d3 !important;
  color: #fdb930 !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
  align-items: flex-end !important;
}
.btn-visa {
  background-color: #cccede !important;
  color: #06306f !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
  align-items: flex-end !important;
}
.flex-item h1 {
  padding-top: 2rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #5e5e5e !important;
  margin-bottom: -0.8rem;
}

.flex-item a {
  color: #76a0d0 !important;
  font-size: 0.9rem !important;
  font-weight: 300 !important;
}

.okjii-offer-part .h1-trusted {
  padding-top: 2rem !important;
}

.carousel {
  margin-top: 20px;
}
.carousel-item {
  text-align: center;
  min-height: auto;
  display: flex;
  flex-direction: row !important;
}

.carousel-item p {
  text-align: left !important;
}

.item {
  width: 100px !important;
  height: 50px !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: space-between !important;
}
.bs-example {
  margin: 20px;
}

.okkji-howtoapply {
  padding: 10rem !important;
}
ol.okkji-howtoapply-list {
  padding: 5rem !important;
}

.bolded-list-indicator li {
  font-weight: 700;
  font-size: 1.1rem;
}

.bolded-list-indicator li p {
  font-weight: 400;
  padding-left: 2rem;
  font-size: 1.1rem;
}

.navbar-brand .mobile {
  display: none !important;
}

img.img-fluid.mobile {
  display: none;
}
.okkji-ourpractice .mobile {
  display: none !important;
}

.okjii-home.main-body-mobile {
  display: none !important;
}

.carousel-control-next {
  top: 105% !important;
}

.carousel-control-prev {
  top: 105% !important;
}

.text-green {
  color: #259d70;
}
.new-customer div {
  overflow: scroll !important;
}
.retail-store > div {
  overflow: scroll !important;
  min-height: 75vh;
}
.store-customer-order .profile p:first-child{
  margin-bottom: 0 !important;
  font-weight: 600;
  text-align: right;
}
.store-customer-order .profile p:last-child{
  font-size: .9rem;
  margin-top: 0rem !important;
  text-align: right;
}
.store-customer-order .profile{
  margin-right: 1rem !important;
}
.store-customer-order .callIcon{
  width:70%;
}
.text-orange {
  color: #E5582D;
}

.okjii-offer-image {
  width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 50px !important;
  z-index: -1;
}
figure.figure-whayweshop img {
  border-radius: 15px !important;
}

/* About Us Page */

.about {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.okkji-about h1 {
  font-weight: 500;
  color: #5e5e5e !important;
  font-size: 17pt;
  padding-top: 5.5rem !important;
}

.okjii-digital {
  position: absolute;
  top: 65px;
  left: 53px;
}

/*.okjii-digital h1{
  font-size:2.1rem !important;
  color:#5e5e5e !important;
  font-weight: 500;
  line-height: 2em;
  letter-spacing: 0.7px;
  padding-top:1.48rem;
}*/

.about h1 {
  font-weight: 600;
  color: #5e5e5e !important;
  font-size: 1.2rem;
  text-align: center;
  padding-bottom: 0.5rem;
}

.about h2 {
  padding-bottom: 2rem !important;
  font-size: 1.1rem !important;
  color: #5e5e5e !important;
  letter-spacing: 0.3px;
  text-align: center;
}

.about h3 {
  padding-top: 1.5rem;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: #5e5e5e !important;
  text-align: center;
}
.about p {
  font-size: 1rem;
  color: #5e5e5e !important;
  padding-top: 0.2rem;
  text-align: justify;
}

.okjii-about-para {
  padding-top: 1.5rem !important;
}

.our-team {
  background-color: #f7f7f7;
  padding: 2rem;
}

.our-team h1 {
  font-weight: 600;
  color: #5e5e5e !important;
  font-size: 1.2rem;
  text-align: center;
  padding-bottom: 2rem;
}

.our-team .Mannu {
  width: 8%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.our-team .Vicky {
  width: 30%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.our-team figcaption h2 {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  color: #5e5e5e;
  padding-top: 1rem;
  padding-bottom: 0px;
}
.our-team figcaption i {
  color: #0197d2;
}
.our-team figcaption p {
  font-size: 1rem;
  font-weight: 300;
  color: #5e5e5e;
  text-align: center;
  margin-top: -1.5rem;
}

.row.our-teams {
  justify-content: center !important;
}

.our-story {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.our-story h1 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  padding-bottom: 3rem;
  color: #5e5e5e;
  text-align: center;
}

.our-story h2 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  color: #5e5e5e;
}
.our-story p {
  font-size: 1rem;
  color: #5e5e5e;
  text-align: justify;
}

.our-story img {
  width: 50%;
}

.okjii-customerkirana {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.okjii-customerkirana h1 {
  font-size: 1.2rem !important;
  padding-bottom: 2rem;
  color: #5e5e5e;
}

.okkji-ourpractice {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.okkji-ourpractice h1 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #5e5e5e;
}

.okkji-ourpractice h2 {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  color: #5e5e5e;
}

.okkji-ourpractice .getapp-link {
  padding-top: 2rem;
}

.getapp-link .form-control {
  padding: 1.5rem !important;
}

.getapp-link .input-group-append {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}

.getapp-link input {
  margin-top: 0rem !important;
  margin-left: -1em !important;
  padding-left: 0.2 !important;
  border: none !important;
}

.okkji-ourpractice p {
  font-size: 1rem;
  color: #5e5e5e;
  text-align: justify;
}

.okkji-ourpractice .ourpractice {
  padding-top: 12rem;
}
.img-apple {
  padding-top: 2rem;
  width: 25%;
}

.okjii-home .getapp-link {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.getapp-link .input-group {
  background-color: #ffffff !important;
  border-radius: 5px !important;
}

.getapp-link .form-control {
  margin-left: -1.1rem;
  margin-top: 0.1rem;
  font-size: 0.98rem;
  vertical-align: middle;
}

input.form-control::placeholder {
  font-size: 0.9rem;
}

.getapp-link.form-control {
  margin-left: -1rem;
  padding-left: 0 !important;
  padding-top: 1rem !important;
}

.getapp-link .input-group-lg > .form-control:not(textarea) {
  height: calc(1em + 3rem + 2px) !important;
  background-color: #ffffff;
}

.input-group-lg > .form-control {
  padding: 0.2rem 1.5rem;
  font-size: 0.9rem !important;
}

.input-group .form-control:not(:first-child):not(:last-child) {
  height: 100% !important;
}
.getapp-link .input-group-append {
  margin-left: 0px;
}
.getapp-link .input-group-append .input-group-text {
  background-color: #259d70 !important;
  height: 100%;
  padding: 10px !important;
  color: #fff !important;
  font-size: 0.9rem !important;
  /* border-radius: 5px !important; */
  /* margin-top: 16px !important;
  margin-right:8px !important; */
}

.get-applink {
  display: flex;
  width: 50% !important;
  vertical-align: middle;
}

.btn-offerpage {
  margin-top: 4rem !important;
}
.offer-input {
  margin: 0px;
}

/* Contact Us Page Css*/

.okjii-contactus h1 {
  font-size: 1.2rem;
  color: #5e5e5e;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.okjii-whatsapp {
  padding-bottom: 3rem;
}

.okjii-contactus .card-address {
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 5px;
  font-weight: 400;
  text-align: center;
  padding-top: 2.3rem;
  padding-bottom: 1.4rem;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card-address h3 {
  font-size: 1.1rem;
  color: #5e5e5e;
  padding-top: 1.2rem;
  padding-bottom: 0.7rem;
  font-weight: 500;
}

.card-address p {
  font-size: 1rem;
  color: #5e5e5e !important;
}

.okjii-contactus .okjii-account {
  padding-top: 4rem;
}

.okjii-retailor-body h1 {
  padding-top: 11rem !important;
}

.okjii-retailer .h1-trusted,
.ok-fast .h1-trusted {
  padding-top: 3rem;
}
.okjii-cardbody .h1-trusted,
.okkji-faqs .h1-trusted {
  padding-top: 3rem !important;
}

.pre-regbody .h1-trusted {
  padding-top: 1rem !important;
  margin-top: -4rem;
}

.okjii-home .h1-trusted {
  padding-top: 3rem;
}

.about-getapplink {
  margin-left: 19rem;
  margin-right: 19rem;
}

/* Terms and Condition */

.term-condition {
  padding-top: 61px;
}
h1.term-h1-heading {
  font-size: 17pt;
  font-weight: 700;
  color: #5e5e5e !important;
  background-color: #f9f9f9;
  padding-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 3rem !important;
  padding-right: 0 !important;
  padding-bottom: 25px;
  vertical-align: middle;
}

.about-body .h1-trusted,
.offer-body .h1-trusted,
.faq-body .h1-trusted {
  padding-top: 0rem;
  margin-top: -1rem;
}

.blog-body .h1-trusted {
  padding-top: 0.5rem;
}
.okjii-term-condition {
  padding-left: 3rem;
  padding-right: 3rem;
}
.okjii-term-condition p:first-child {
  padding-top: 1rem;
}

.okjii-term-condition p {
  font-size: 1rem;
  color: #5e5e5e !important;
}

.okjii-term-condition h2 {
  font-size: 1.2rem;
  color: #5e5e5e;
  padding-bottom: 1rem;
  font-weight: 600;
}
.okjii-term-condition h3 {
  font-size: 1.1rem;
  color: #5e5e5e;
  padding-bottom: 1rem;
  font-weight: 600;
}

.okjii-term-condition ul {
  padding-left: 1.2rem;
}

.okjii-term-condition ul li {
  line-height: 1.5;
  padding-bottom: 1rem;
  color: #5e5e5e;
}

.offerheading {
  padding-left: 1rem !important;
}
/* Okjii Blog start  */
.okjii-blog,
.okjii-regbody,
.blog-page {
  padding-top: 51px !important;
}
.okjii-heading {
  position: relative;
  text-align: center;
}
.okjii-heading h1 {
  color: #fff;
}
.okjii-blog-h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  font-weight: 700;
}

.blog-column {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.blog-column h3 {
  font-size: 0.9rem;
  columns: #5e5e5e;
  padding-top: 0.4rem;
  margin-bottom: 0;
}

.blog-column h2 {
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-bottom: 0;
}

.blog-column p {
  font-size: 1rem;
}

.blog-button {
  color: #195fb0 !important;
  font-weight: 700 !important;
  text-decoration: none;
  padding: 10px 1px;
  padding-left: 0 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.blog-column .a:hover {
  text-decoration: none !important;
}
.mdb-icon {
  font-size: 0.9rem;
  line-height: 1.5 !important;
  padding-right: 1rem !important;
  padding-top: 0px;
  padding-right: 1.5rem;
  color: #195fb0;
}

a.blog-button:hover {
  text-decoration: none !important;
}

.number-flower {
  padding-right: 0.5rem;
  color: #5e5e5e !important;
  font-weight: 700;
  padding-top: 0px;
}
.bdr-bottom {
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 1rem;
}
.more-post-button {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.more-post-button button {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #fff;
  color: #5e5e5e;
  border-top: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  border-right: none;
  border-bottom: none;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
}

/* Okjii Blog end */
/* Okjii BlogPage start */
.container-fluid.blog-information {
  padding-left: 3rem;
  padding-right: 3rem;
}
.blog-information img {
  width: 100%;
  padding-right: 0rem;
  padding-left: 10rem;
  vertical-align: middle;
}

.blog-information h1 {
  font-size: 17pt;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.blog-information h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-bottom: 1rem;
}
.blog-information p {
  font-size: 1rem;
  color: #5e5e5e;
}

p.card-text {
  margin-top: -1rem;
}

.social-cards {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.social-network {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.social-card {
  padding-left: 2.1rem;
  padding-right: 2.1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.social-card .far,
.social-card .fab {
  font-size: 1.3rem;
  margin-right: 1rem;
}

.text-social {
  color: #5e5e5e;
  font-size: 1rem;
  font-weight: 600;
}
.social-card.love {
  border: 1px solid #f95555;
  color: #f95555;
}
.social-card.facebook {
  border: 1px solid #6763e3;
  color: #3c5a99;
}
.social-card.twitter {
  border: 1px solid #7fdbf0;

  color: #33ccff;
}
.social-card.whatsapp {
  border: 1px solid #4b8c4b;
  color: #61fc7d;
}
.social-card.story {
  border: 1px solid #f674dd;
  color: #d33d98;
}
.rating-card {
  margin-bottom: 2rem;
}

.star {
  border: 1px solid #bdbdbd;
  width: 45%;
  margin: 0 auto;
  padding: 1.5rem;
}

.leave-reply-box {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.leave-reply-box h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 4rem;
}

.leave-reply-outer {
  border: 1px solid #bdbdbd;
  width: 80% !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0 auto;
}
.leave-reply {
  width: 90%;
  margin: 0 auto;
}
.text-star {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
}
.text-star-right {
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
}

.star .fa-star {
  font-size: 2rem;
  color: #898989;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.leave-field {
  width: 90%;
  margin: 0 auto;
}

.leave-field .form-control,
.leave-reply .form-control {
  border: 1px solid #bebebe !important;
  padding: 0.5rem 0.75rem !important;
  align-content: center;
}
.leave-field {
  display: flex;
  padding-bottom: 1rem;
  margin-top: 2rem;
}
.leave-field input {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.leave-button .btn {
  background-color: #259d70 !important;
  font-weight: 600;
}
.related-blog h1 {
  font-size: 17pt;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 0rem;
  padding-bottom: 1rem;
}

/* Okjii BlogPage end */

/* Registration start */

.okjii-reg {
  position: relative;
  text-align: left;
  color: #fff;
}
.okjii-reg-h1 {
  position: absolute;
  top: 7rem;
  left: 3rem;
  color: #fff !important;
}

.okjii-regbody h1.okjii-reg-h1 {
  font-size: 42pt;
  font-weight: 500 !important;
}
.pre-okjii-black {
  color: #5e5e5e;
}
.textbuttonotp {
  position: relative;
  text-align: right;
  color: #fff;
}
.btn-getotp {
  position: absolute;
  top: -3.3rem !important;
  right: 0.4rem !important;
}
.btn.btn-getotp,
.btn.form-btn {
  background-color: #259d70 !important;
  color: #fff;
}
.okjii-registration {
  background-color: #fff;
}

.textbuttonotp {
  margin-bottom: -3rem;
}

.okjii-registration input {
  background-color: #ededed;
  padding: 1rem !important;
}
.okjii-registration .form-control {
  height: calc(1.5em + 2rem + 2px) !important;
}

.form-inline .form-check {
  padding-right: 4rem;
}
.okjii-registration .btn {
  padding: 0.6rem 1.8rem !important;
}
a.nav-link.Ripple-parent.pre-registration {
  background-color: #259d70;
  color: #fff !important;
  border-radius: 5px;
}

.form-table {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.okjii-registration form {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.okjii-registration input {
  padding-left: 1rem !important;
  padding-right: 28rem !important;
}
.sound-signal h1 {
  font-size: 18pt;
  font-weight: 700;
  color: #259d70 !important;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.sound-signal p {
  margin-bottom: -1rem !important;
}

.reg-offerpage label {
  font-size: 1rem;
  color: #5e5e5e;
}

.reg-offerpage img {
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0 auto;
}

input[type="radio"] + label:before {
  margin-left: 2px !important;
}

.okjii-regbody h1 {
  font-size: 18pt;
  font-weight: 700;
  color: #5e5e5e;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.okjii-registration {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.reg-howtoapply {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.okjii-regbody p {
  font-size: 0.9rem;
  columns: #5e5e5e;
  font-weight: 500;
  padding-bottom: 0rem !important;
}
.okjii-reg-h1 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 5px;
  line-height: 1.8;
}
.pre-okjii {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1;
}
.form-table {
  padding-bottom: 0rem;
}
.reg-howtoapply {
  background-color: #f7f7f7;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bolded-list-indicator {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
    font-weight: 600 !important;
}
.bolded-list-indicator li {
  font-size: 1.3rem;
  color: #5e5e5e;
  font-weight: 700;
}

.bolded-list-indicator li p {
  font-size: 1rem;
  color: #5e5e5e;
  margin-bottom: 0.5rem !important;
}

input[type="radio"] {
  display: none;
}
input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 3px;
  margin-right: 15px;
  margin-left: 15px;
  vertical-align: middle;
  background-clip: content-box;
  border: 1px solid #259d70;
  background-color: #fff;
  border-radius: 50%;
}

input[type="radio"]:first-child {
  transform: translateX(100px);
}

input[type="radio"]:last-child {
  transform: translateX(200px) !important;
}

/* appearance of checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: #259d70;
  vertical-align: middle;
}
.sound-signal {
  margin-bottom: 1rem;
}
.sound-signal input[type="radio"] + label:before {
  margin: 10px;
}
.preReg-radio-label input[type="radio"] + label:before {
  margin: 10px;
}
.howtoapply {
  font-size: 18pt;
  font-weight: 700;
  color: #5e5e5e;
}

.Ripple {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
}
/* Registration End */

/* Career Css start */

.career-body,
.career-page {
  padding-top: 50px;
}

.career-body-img {
  position: relative;
}

.career-left {
  position: absolute;
  top: 12rem;
  left: 3rem;
}

.career-page-left {
  position: absolute;
  top: 12rem;
  left: 3rem;
}

.career-left {
  font-size: 18pt;
  font-weight: 600;
  color: #5e5e5e;
  width: 35%;
  line-height: 2;
}

.carrier-blog {
  padding-top: 2rem;
  padding-left: 2rem;
}
.carrier-blog h1 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
}

.carrier-blog p {
  font-size: 1rem;
  color: #5e5e5e;
  line-height: 1.5;
}

.carrier-blog img {
  width: 40%;
  margin: 0 auto;
  display: block;
}

.career-offer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.career-offer h1 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
  padding-left: 1.2rem;
}

.career-offer h2 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #5e5e5e;
}

.career-offer p {
  font-size: 1rem;
  color: #5e5e5e;
  margin-top: -1.5rem;
}

.career-card {
  display: flex;
}

.career-card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #5e5e5e;
  width: 60% !important;
  padding: 5px;
  margin: 1rem;
  margin-left: 4rem;
  margin-right: 4rem;
}

.career-card img {
  width: 70px;
  height: 70px;
  padding-right: 1rem;
  margin-top: 5px;
}

.career-card h2 {
  margin-top: 10px;
}
/* Career Css end  */

/* Career page Css start */
.career-page-left {
  width: 23%;
}

.career-page-left h1 {
  font-size: 18pt;
  color: #5e5e5e;
  font-weight: 600;
}

.career-page-left p {
  font-size: 1rem;
  color: #5e5e5e;
}

.career-page h1 {
  font-size: 18pt;
  color: #5e5e5e;
  font-weight: 600;
}

.career-technical {
  padding-left: 3rem;
  padding-right: 3rem;
}

.career-technical h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
}
.career-technical p {
  font-size: 1rem;
  color: #5e5e5e;
}

.career-technical ul {
  padding-left: 3rem;
}

.career-technical ul:last-child {
  padding-bottom: 2rem;
}

.career-apply {
  background-color: #f4f4f4;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.applypage h1 {
  font-size: 1.2rem;
  color: #5e5e5e;
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 0.8rem;
}
.card.applypage {
  background-color: transparent;
  box-shadow: none;
}

.applypage input {
  padding: 1rem;
}

.applypage .custom-file-label::after {
  display: none;
}

.applypage .custom-file-label {
  background-color: transparent;
  color: #3156f4;
  border: none;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 600;
}
.applypage .my-top {
  margin-top: -2rem;
}
.location-icon {
  height: 20px;
  margin-right: 0.5rem;
}
.applypage .btn {
  padding-left: 3rem;
  padding-right: 3rem;
}

/* Career page Css End */
/* Refer and Earn page Css Start */
.refer-earn-mobile {
  display: none;
}

.refer-earn-desktop {
  display: block;
}
.container.refer-earn-form-mobile {
  display: none;
}

.container.refer-earn-form-desktop {
  display: block;
}

.refer-earn {
  padding-top: 80px;
}

.refer-earn {
  position: relative;
}

.refer-earn-content {
  position: absolute;
  top: 6rem;
  left: 3rem;
}

.refer-earn-con h1 {
  font-size: 18pt;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 3rem;
  margin-bottom: 1rem;
}

.refer-earn-con p {
  font-size: 1rem;
  color: #5e5e5e;
}

.Referandearn {
  width: 80%;
  display: block;
  margin-left: 5rem;
  margin-right: 0px;
}

.refer-earn-apple {
  width: 62%;
  position: absolute;
}
.refer-earn-apple .install-now {
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
}
.refer-earn-apple .Asset7-install {
  width: 23px;
  padding-bottom: 1rem;
}

.refer-earn-get-applink {
  padding-top: 2rem;
}

.refer-earn-how-to {
  margin-top: 3rem;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
  padding: 2rem;
}

.refer-earn-how-to h1 {
  font-size: 1.2rem;
  color: #5e5e5e;
  font-weight: 600;
  padding-left: 4rem;
  padding-right: 2rem;
}

.refer-earn-how-to ul {
  padding-left: 4rem !important;
  padding-right: 2rem !important;
}

.refer-earn-how-to ul li {
  padding-bottom: 1rem;
}

.okjii-referearn {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.refer-earn-how-to .okjii-whatsapp.col-sm-12.col-md-4 {
  padding-left: 0;
}

.earn-how {
  padding-bottom: 4rem;
}

.refer-earn-refer {
  margin-top: 3rem;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
  padding-bottom: 2rem;
}

.refer-earn-refer h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 2rem;
}

.refer-earn-refer h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-bottom: 0.5rem;
}

.refer-earn-refer .btn {
  background-color: #259d70 !important;
  padding-left: 2rem;
  padding-right: 2rem;
}

.refer-earn-refer input {
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
}

.refer-term-condition {
  padding-top: 3rem;
  text-align: center;
}

.refer-term-condition h1 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5e5e5e;
}
.refer-term-condition p {
  font-size: 1rem;
  color: #5e5e5e;
}

/* Refer and Earn page Css End */

/* Upload  Selfy start*/

.selfi-blog {
  display: flex;
  justify-content: center;
  min-height: auto !important;
  margin-left: 0rem;
}
.selfi-blog {
  position: relative;
  height: auto;
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}

.selfi-blog .right1,
.selfi-blog .left1 {
  border: 1px solid #259d70;
  width: 530px;
  height: 560px;
}
.selfi-blog {
  margin-top: 100px;
  margin-bottom: 100px;
}

.selfi-blog p {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  color: #259d70;
}

.selfi-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selfi-blogicon {
  margin: 0 auto;
  width: 100%;
  display: block;
  margin-top: -1.5rem;
}

.selfi-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #259d70;
  padding: 5px;
}

.selfi-blog button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 5rem;
}

/*  Upload selfy end*/

/*  Kirana Store start*/

.kirana-store {
  padding-top: 80px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 40px;
}

.retail-shop p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #5e5e5e;
}

.retail-shop a,
.retail-shop button {
  background-color: #259d70 !important;
  font-weight: 400;
  text-transform: none;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  color: #fff;
  padding-bottom: 1rem;
}

.retail-shop-background {
  padding: 15px;
}
.retail-shop-background p {
  font-size: 0.9rem;
  color: #5e5e5e;
  margin-top: 0.4rem;
}
.retail-shop-background label {
  font-size: 0.9rem;
  color: #5e5e5e;
}
/*  kirana Store End  */

/*  Dash Bord Card  Start */
.dashbord-card1 {
  background-image: linear-gradient(-90deg, #6071e3, #805fe4);
  height:160px;
}
.dashbord-card2 {
  background-image: linear-gradient(-90deg, #12b0ef, #1173ee);
  height:160px;
}

.dashbord-card3 {
  background-image: linear-gradient(-90deg, #f53759, #f55d38);
  height:160px;
}

.dashbord-card4 {
  background-image: linear-gradient(-90deg, #17294d, #1b184d);
  height:160px;
}

.dashbord-card1 i,
.dashbord-card1 p,
.dashbord-card1 .stats,
.dashbord-card2 i,
.dashbord-card2 p,
.dashbord-card2 .stats,
.dashbord-card3 i,
.dashbord-card3 p,
.dashbord-card3 .stats,
.dashbord-card4 i,
.dashbord-card4 p,
.dashbord-card4 .stats {
  color: #fff !important;
}
.card-footer .footer-card1 i {
  border-radius: 50%;
  background-color: #fff;
  color: #6071e3 !important;
  padding: 5px;
}
.card-footer .footer-card2 i {
  border-radius: 50%;
  background-color: #fff;
  color: #12b0ef !important;
  padding: 5px;
}
.card-footer .footer-card3 i {
  border-radius: 50%;
  background-color: #fff;
  color: #f53759 !important;
  padding: 5px;
}
.card-footer .footer-card4 i {
  border-radius: 50%;
  background-color: #fff;
  color: #17294d !important;
  padding: 5px;
}
.okjii-dashboard .card-footer .stats {
  border-top: 1px solid #fff !important;
  margin-top: -1rem;
  padding-top: 5px;
}

.card-list-body .card-body {
  padding-left: 15px;
}

.card-list-body .card-category {
  border-bottom: 1px solid #DCDCDC !important;
  padding-bottom: 8px;
}

.card-list-body .card-body div:first-child{
  padding-top: 0px !important;
}

.marketing-dashbord .Acceptorder{

  width:78%;

}

.marketing-dashbord .Target{

  width:85%;

}

.marketing-dashbord .Performence{
 width:6%;
}
.customer-filter p span {
    color: #5e5e5e;
    font-weight: 500;
    margin-left: 10px;
}

.marketing-dashbord .card-title{
  padding-top:1rem;
}


.card-list-body .card-body .card-list {
  border-bottom: 1px solid #EAEAEA !important;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.okjii-dashboard h5{
  color:#5e5e5e;
}

.card-list-body .card-body div{
  font-size:.9rem;
  color:#5e5e5e;
}
.dashbord-text {
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 1.2rem;
}

.sidebar:before,
.sidebar:after,
.off-canvas-sidebar:before,
.off-canvas-sidebar:after {
  display: block;
  content: "";
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eeeeee !important;
  top: 0;
  left: 0;
}
.sidebar,
.off-canvas-sidebar {
  border-right: none !important;
}
.sidebar .logo{
  margin-bottom: 14px;
}
.sidebar .logo:after,
.off-canvas-sidebar .logo:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: #eeeeee !important;
}

nav.navbar-absolute.fixed-top.navbar-transparent.navbar.navbar-expand-lg.bg-transparent {
  background-color: #eeeeee !important;
  border-bottom: none !important;
}

.sidebar .logo a.logo-mini,
.off-canvas-sidebar .logo a.logo-mini {
  opacity: 1;
  float: left;
  width: 100px !important;
  text-align: center;
  margin-left: 10px;
  margin-right: 12px;
}

input.no-border.form-control {
  background-color: #fff !important;
}
.sidebar .nav,
.off-canvas-sidebar .nav {
  margin-top: 0px !important;
  display: block;
}
.sidebar{
  width: 220px !important;
}
.sidebar .nav i{
  font-size: 18px !important;
}
.sidebar .nav a p,
.sidebar .nav a i {
  color: #444444 !important;
}
.sidebar .sidebar-wrapper ul.nav li.active a.nav-link.active ::before{
  border: none;
}
.sidebar .sidebar-wrapper li.active a.nav-link.active ::after{
  border: none;
}

.logo-normal i {
  color: #5f5f5f !important;
}
.nav li.active {
  background-color: #259d70 !important;
}
.sidebar a.nav-link.active {
  background-color: #259d70 !important;
}
.sidebar .sidebar-wrapper{
  width: 220px !important;
}
.nav-btn i {
  color: #259d70;
}

.nav-btn i {
  margin-right: 2rem;
}

.nav-btn i:last-child {
  margin-right: 0rem !important;
}
li.nav-btn.nav-item {
  display: inline-flex;
  margin: 0 auto;
}
.sidebar p{
  text-transform: none !important;
}
.sidebar a.nav-link.active p,
.sidebar a.nav-link.active i {
  color: #fff !important;
}
/* DashBoard Card End */
/* Customer Page Start */

.customer-table {
  background-color: #fff;
  border-radius: 15px;
}

.customer-table .btn {
  margin-left: 1rem !important;
  padding: 11px;
  text-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f7f6 !important;
  border-radius: 10px !important;
}

.table-striped th,
.table-striped td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none !important;
}
.table-striped img.rounded-circle.testimoni-img {
  margin-left: 0rem !important;
  margin-left: 0.5rem !important;
  margin-right: 3rem !important;
}

.table-striped .btn {
  margin: 0;
  background-color: #fff !important;
  color: #5e5e5e;
  font-size: 1.2rem;
}

.customer-filter {
  border-bottom: 1px solid #ECECEC !important;
  margin-bottom: 2rem;
}
.customer-filter p {
  font-size: 1.2rem;
}
.customer-filter p .fa-search {
  background-color: #259d70;
  border-radius: 50%;
  padding: 6px;
  font-size: .8rem;
  color: #fff;
  transform: rotate(90deg);
}
.coupion-content .customer-filter i.fa-plus{
  background-color: #5484F4;
  border-radius: 50%;
  padding: 8px;
  font-size: .8rem;
  color: #fff;

}


.customer-filter i.fa-plus{
  background-color: #259d70;
  border-radius: 50%;
  padding: 8px;
  font-size: .8rem;
  color: #fff;

}

.customer-filter i.fa-plus{
  margin-left:1rem;
}


.customer-name{
  display: flex;
}

.product-details .ant-form-item-label{
  display:none;
}

.filter-search {
  display: flex !important;
  flex-wrap: nowrap;
}
.customer-filter .filter-txt {
  color: #259d70;
  padding-right: 1rem;
  padding-left: 0rem;
}

.kirna-store-name {
  margin-top: 15px;
}
.customer-details {
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 3rem;
}

.customer-details p {
  font-size: 1rem;
  color: #747474;
}
.customer-details .btn {
  background-color: #fff !important;
  padding: 5px 22px !important;
  border: 1px solid #5e5e5e;
  color: #5e5e5e;
  font-weight: 500;
  font-size: 0.9rem;
}

.customer-details p .number-properties {
  font-size: 0.9rem;
  color: #c4c4c4;
}

.custome-switch {
  display: flex;
}

.custome-switch label {
  padding-right: 2rem;
  padding-top: 1rem;
}
.custome-switch .custom-switch {
  padding-top: 1rem;
}

.custom-switch .custom-control-label::before {
  content: "";
  left: -2.25rem;
  width: 3.75rem !important;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-color: #fff;
  transform: translateX(2.75rem) !important;
}

/*  Customer Page End */
/* Customer Login start */

.mobile-login{
  display: none;
}


img.img-fluid.Logowhite-bg {
  transform: translate(-1002px, -61px);
  width: 32%;
  left:0;
}


.login-information {
  padding-top: 10rem;
  margin: 0 auto;
}
.login-information h1 {
  font-size: 18pt;
  color: #5e5e5e;
  font-weight: 600;
  padding: 2rem 0rem;
}

.login-information .input-group-lg > .form-control {
  height: calc(1.5em + 1rem + 2px) !important;
  background-color: #e9ecef;
}

.offer-input input.form-control {
  margin-top: 0.3rem;
}

.otp-input .input-group-lg > .form-control:not(textarea) {
  margin: 1rem !important;
  background-color: #e9ecef;
  border: 1px solid green !important;
  border-radius: 5px !important;
}

.login-page-information{
  position: relative;
  text-align: center;
  
}

/* .login-page:before {
  background-color: rgba(255, 255, 255,.1);
  background-image: url(../src/img/login/background.jpg);
  background-repeat: no-repeat;
  background-position: 80%;
  width: 100%;
  height: 80%;
  position: fixed;
  content: "";
} */

.card {
    border-radius: 8px !important;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    border: 0 none;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}

.login-page-right {
  position: absolute;
  top: 8px;
  right: 120px;
}



.logo-white-login{
  position: relative;
  text-align: center;

} 
.top-left-login{
  position: absolute;
  top: 8px;
  left: 16px;
}
.Logowhite-bg{
  z-index: 99999 !important;
  position: absolute;
}
.login-page-information img:first-child{
  margin-top:-0.4rem;
}

.login-information .btn {
  padding: 0.7rem 2rem;
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.login-information p {
  font-size: 1rem;
  color: #5e5e5e;
  background-color: none !important;
  margin-bottom: 6rem;
}
/* Customer Login End  */
/*  Blog Page Start  */
.blog-table {
  background-color: #fff !important;
}

.blog-table .table-striped {
  margin-left: 0rem;
  margin-right: 0rem;
}

.blog-table thead,
.blog-table tbody,
.blog-table tr {
  border: 1px solid #f8f9f9;
}
.blog-table td {
  border: 1px solid #f8f9f9;
}

.table-striped.blog-table tbody tr:nth-of-type(odd) {
  background-color: #fbfcfd !important;
  border-radius: 10px !important;
}

.text-green-background {
  background-color: #3ccd5e;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
}

.table-striped .btn {
  margin: 0;
  background-color: #fff !important;
  color: #5e5e5e;
  font-size: 1.2rem;
  padding: 2px 8px;
}
.blog-navbar {
  background-color: #fff;
  widows: 100%;
  height: 60px;
  margin-bottom: 3rem;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
}

.blog-navbar .col-md-10 p:first-child {
  margin-top: 0.8rem;
}

.blog-navbar .btn {
  background-color: #5484f4 !important;
  padding: 10px 10px;
  font-size: 0.9rem;
}

.add-blog {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 10px;
}

.add-blog .form-group input[type="file"] {
  opacity: 1;
  margin-top: 2rem;
}
.add-blog .btn,
.add-blog .btn .text-center {
  background-color: #3ccd5e !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  padding: 5px 10px;
}

/*  Blog Page End   */

/*  Notification Page start */

.notification-container .customer-filter p{
  font-size:1.1rem;
  color: #5e5e5e;
  font-weight: 500;
}

.greter-than{
  margin-left:1rem;
  margin-right: 1rem;
}

.filter-txt{
  font-size:1rem;
  font-weight: 500;
}
.pluse-sign i{
  background-color: #259d70;
  border-radius: 50%;
  padding: 10px;
  color:#fff;
  font-size:1rem;
}

.notification-container .customer-table {
  margin-top: 2%; 
  border: 2px solid #fff;
  box-shadow: 0px 1px 3px 3px #cfcfcf;
  border-radius: 5px !important;
}

.notification-container .customer-table thead th{
  padding:1.5rem;
  border-bottom: 1px solid #757676;
  border-right:1px solid #757676;
  vertical-align: middle;
}
.notification-container .customer-table tbody td{
  padding:1.5rem;
  border-right:1px solid #757676;
  vertical-align: middle;

}


.notification-container input:focus ~ label, input:valid ~ label {
  top: auto !important;
  font-size: 14px;
  color: #444444;
}

.notification-container .custom-control {
  width: auto;
}

.notification-container .close{
  display:none;
} 

.notification-container h4{
margin-top:.5rem;
font-size: 1.2rem;
font-weight: 500;
color:#289C71;
}

.notification-container .modal-body{
  margin-top:1rem;
  
}

.notification-container .modal-body input, .notification-container .modal-body select, .notification-container .card{
  width:80%;
}
.notification-container .form-control{
  border:1px solid #289C71 !important;
  font-size: .8rem;
  border-radius: none !important;

}
.notification-container .input::placeholder{
  font-size:.9rem;
}

.notification-container .modal-footer{
  border-top:none;
}

.notification-container .modal-footer .btn:first-child{
  background-color: #259D70 !important;
  text-transform: uppercase !important;
  padding: .5rem 2rem;
  font-weight: 400 !important;
}

.notification-container .modal-footer .btn:last-child{
  text-transform: uppercase !important;
  padding: .5rem 1rem;
  font-weight: 400 !important;
  border:1px solid #259d70 !important;
}

.notification-container .modal-footer .btn:last-child:hover{
  background-color: #259d70 !important;
}

.notification-container .dropdown-item:hover{
  background-color: #fff !important;
}

.notification-container .collesp-header{
  margin-bottom: 0px;
  padding: 0rem .7rem;
}

.notification-container .collesp-header h4{
  font-size:1rem;
  color:#5e5e5e;
}

.notification-container .card{
  box-shadow: none;
  border:1px solid #259d70;
  border-radius: 5px;

}

.notification-container .ant-switch-inner{
  color:#259d70;
  text-transform: uppercase;

}

.notification-container .ant-switch-checked, .notification-container .ant-switch {
  background-color: transparent !important;
  border:1px solid #259d70;
}

.show.basic.dropdown-menu.dropdown-bottom {
  top: 30px !important;
  left: 30px !important;
}

.notification-container .dropdown-menu{
  border-radius: 0px !important;
}
/* Notification Page end */

/* Message Page Start */
.message-container .customer-table {
  margin-top: 2%; 
  /* box-shadow: 1px 1px 11px 3px #cfcfcf; */
  border-radius: 5px !important;
}

.message-container .customer-table thead {
  border: 2px solid #F2F3F5;
  vertical-align: middle;
}
.message-container .customer-table thead th{
  padding: 1rem 1rem !important;
}

.message-container .customer-table tbody td{
  padding:1rem !important;
  border:1px solid #F2F3F5;
  vertical-align: middle;

}

.bg-support{
  background-color: #DB3E3C;
  border-radius: 5px;
  padding: 3px 10px;
  color:#fff;
  font-style: italic;
  }
  .no-break {
    white-space: nowrap;
  }
  .bg-read{
    background-color: #41CB5B;
    border-radius: 5px;
    padding: 3px 10px;
    color:#fff;
    font-style: italic;
    }
    .bg-callback{
      background-color: #5BBADF;
      border-radius: 5px;
      padding: 3px 10px;
      color:#fff;
      font-style: italic;
      }
      .bg-complain{
        background-color: #EEB837;
        border-radius: 5px;
        padding: 3px 10px;
        color:#fff;
        font-style: italic;
        }
    
    .eye-outline .anticon-eye{
      background-color: #41CB5B;
      color:#fff;
      padding: 3px 3px 3px 3px;
      margin-top:0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }


    .eye-outline .anticon-close-square{
      color:#F86517;
      font-size:1.4rem;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  

/* Message Page End */

/* Product Page Start */

.product-details input:focus ~ label, input:valid ~ label {
  top: auto !important;
  font-size: 14px;
  color: #444444;
}

.product-details .custom-control {
  width: auto;
}

.product-details .close{
  display:none;
} 

.product-details h4{
margin-top:.5rem;
font-size: 1.2rem;
font-weight: 500;
color:#289C71;
}

.product-details .modal-body{
  margin-top:1rem;
  
}

.product-details .modal-body input, .product-details .modal-body select, .product-details .card{
  width:100%;
}
.product-details .form-control{
  border:1px solid #289C71 !important;
  font-size: .8rem;
  border-radius: none !important;

}
.product-details .input::placeholder{
  font-size:.9rem;
}

.product-details .modal-footer{
  border-top:none;
}

.product-details .modal-footer .btn:first-child{
  background-color: #259D70 !important;
  text-transform: uppercase !important;
  padding: .5rem 2rem;
  font-weight: 400 !important;
}

.product-details .modal-footer .btn:last-child{
  text-transform: uppercase !important;
  padding: .5rem 1rem;
  font-weight: 400 !important;
  border:1px solid #259d70 !important;
}

.product-details .modal-footer .btn:last-child:hover{
  background-color: #259d70 !important;
}

.product-details .dropdown-item:hover{
  background-color: #fff !important;
}

.product-details .collesp-header{
  margin-bottom: 0px;
  padding: 0rem .7rem;
}

.product-details .collesp-header h4{
  font-size:1rem;
  color:#5e5e5e;
}

.poduct-details .card{
  box-shadow: none;
  border:1px solid #259d70;
  border-radius: 5px;

}

.product-details .product-form .form-group{
  margin-right:1rem;
}

.product-details .product-form .form-group:last-child{
  margin-right:0rem;
}

.product-details .form-group{
  padding-bottom: .5rem;
}

.product-details .modal-footer{
  padding-top:.5rem;
  justify-content: center !important;
}

.product-form{
  display: flex;
}

.product-details p{
  font-size: 1rem;
}
.product-details .customer-table{
  margin-top:1%;

}
.product-details .customer-table thead, .reward-content .customer-table thead{
  border-bottom: 1px solid #AEB0AF;
}

.product-details .customer-table thead th, .store-page .customer-table thead th, .customer-information .customer-table thead th, .customer-notification .customer-table thead th, .customer-offer .customer-table thead th, .reward-content .customer-table thead th, .store-order .customer-table thead th {
  padding: 1rem 1rem  !important;
}
.customer-information .customer-table thead th:nth-child(1){
  width: 1px;
}
.customer-information .customer-table thead th{
  padding: 21px !important;
}
.store-page .customer-table thead th:nth-child(1){
  width: 1px;
}


.product-details .customer-table tbod-y td, .store-page .customer-table tbody td, .customer-information .customer-table t+body td, .customer-notification .customer-table tbody td, .customer-offer .customer-table tbody td, .reward-content .customer-table tbody td, .store-order .customer-table tbody td {
  padding: 1rem !important;
}

.customer-notification .customer-table{
  margin-top:1%;
}
.product-details .ant-form-item{
  margin-bottom: 0;
}

.product-details .fa-edit{
  color:#546CF2;
}


.product-details .text-small{
  font-size: .9rem;
  color:#5e5e5e;
}
.product-details .customer-filter p:first-child{
  font-size:1.2rem;
  font-weight: 500;
}
.product-details .ant-select-arrow{
  transform: rotate(270deg) !important;
}
.product-details .ant-form-item .ant-select{
  width: auto !important;
  border-radius: 15px;
}


/* Product Page End */

/*  Customer Notification page start */
.customer-notification .customer-table thead{
border-bottom: 1.5px solid #5e5e5e;
}

.customer-notification .customer-table tbody tr{
  border-bottom: .5px solid #5e5e5e;
}

.customer-notification .customer-filter p{
  font-size:1.2rem;

}

.customer-notification .fa-trash{
  margin-right: 2rem;
  color: #259D70;
}

.customer-notification .filter-txt{
  font-size: .9rem;
  color:#5e5e5e !important;
}

.customer-notification .ant-checkbox-inner{
  width:25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.customer-notification .table-striped img.rounded-circle.testimoni-img {
  margin-left: 1rem !important;
  margin-right: 0rem !important;
}

.customer-offer .table-striped thead tr th{
  border-bottom: 1px solid #5e5e5e;
}

.customer-offer .table-striped tbody tr td{
  border-bottom: none !important;
}

.customer-offer .table-striped tbody tr td:first-child{
  width:15%;
}

.customer-offer .table-striped th.text-center.thead-background{
  background-color: #9BE8C8;
  border-radius: 10px;
  color:#269D70;
  width: 33%;
}

.store-order .table-striped th.text-center.thead-background, .table-striped th.text-center.thead-background {
  background-color: #9BE8C8;
  border-radius: 10px;
  color:#269D70;
}
.customer-offer .table-striped{
  margin-top: 1%;
}
.customer-offer p:first-child{
  padding-top: 0rem !important;
}

.customer-offer p:last-child{
  padding-top: 1rem;
}

.customer-offer .customer-filter p{
  font-size: 1.2rem;
  color:#5e5e5e;
}

.customer-offer p{
  font-size: 1rem;
} 
/* Customer Notification Page End */


/*  Reward Page start */
.reward-content .btn{
  background-color: #259D70 !important;
  text-transform: uppercase !important;
  margin: 0 0 1rem 1rem;
  font-weight: 300 !important;

}

.reward-content .card-stats{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.reward-content .card-category{
  padding-bottom: 1rem;
}
.reward-content .totalearning{
margin-top: .5rem;
width: 75%;
}
.reward-content .acceptorder{
  margin-top: .5rem;
  width: 75%;
  }

  .reward-content .pickpay{
    margin-top: .5rem;
    width: 65%;  
  }
  .reward-content .reviews, .reward-content .callicon{
  margin-top: .5rem;
  width: 72%;
}

.reward-content p:last-child{
  padding-bottom: 1rem;

}

.reward-content .card-title{
  font-size:1rem;
  font-weight: 600;
}
.reward-content .customer-table{
  margin-top: 0%;
}

.reward-content .wallet-row .numbers p:first-child{
  font-size: 1.1rem;
  font-weight: 400;
}
.reward-content .wallet-row .numbers p{
  text-align: left;
  font-size: .9rem;
  margin-bottom: .3rem !important;
}

.reward-content .wallet-row .profile p:first-child{
  font-size: 1.1rem;
  font-weight: 400;
}
.reward-content .wallet-row .profile p{
  text-align: right;
  font-size: .9rem;
  margin-bottom: .3rem !important;

}


/* --------------------common wallet css starts-------------------- */
.wallet-card-button{
    border-radius: 5px;
    box-shadow: 2px 2px 9px 2px #CFCFCF;
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
    border: 2px solid #f9f9f9;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    max-width: 550px;
}
.wallet-note {
  border: 2px solid #259d70;
  text-align: center;
  margin: 0rem 16rem;
  padding: 10px;
}
.wallet-note p{
  margin: 0px;
}

/* --------------------common wallet css ends-------------------- */


.reward-content .wallet-stats .profile-body .profile {
  margin-right: 1.5rem !important;
}

.reward-content .close{
  display: none;
}

.reward-content .modal-title{
  margin-top: 0rem;
}

.reward-content h4{
  font-size: 1.2rem;
  font-weight: 500;
  color:#259D70;
}

.reward-content ol{
  margin-left: 1rem;
}
.reward-content li{
  line-height: 2;
}


.reward-content h4{
  padding-top: .5rem;
  padding-left:1rem;
  padding-right:1rem;
  padding-bottom: 1.5rem;
  margin-top: 1rem;
  border-bottom: 1px solid #dee2e6;

}

.reward-content h4 span{
  font-size: .8rem;
  vertical-align: center;
  color:red;
  font-weight: 400;
}

.reward-content .modal-dialog .modal-content{
  border-radius: 10px;
}

.card.card-payment{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.card-payment{
  width:90%;
  padding:.5rem 2rem;
  margin-top: 1rem;
  margin: auto;
  vertical-align: center;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18) !important;
}

.card-download{
  width:70%;
  padding:0rem 2rem;
  margin-top: 1rem;
  margin: auto;
  vertical-align: center;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18) !important;

}

.card-payment p.payment-Rupee.float-right{
  color:#9A9A9A;
  font-size: 1.2rem;
  font-weight: 400;
}

.card-payment .TotalOnline{
  width:15%;
}

.card-payment .Calender{
  width:10%;
}
.card-payment p{
  margin-top:1rem;
  font-size: 1rem;
}

.card-download p{
  margin-top:1rem;
  text-align: center;
  margin-bottom: 0rem;
  font-size: 1rem;

}


.card-download img{
  margin-right: 1.5rem;
}

.card-download i{
  margin-left: 1rem;
}

.card-payment .TotalOnline {
  margin-right: 2rem;
}

/*  Reward Page end */

/*  Store Order Page Start */

.store-order .Bulb{
  background-color: #259D70;
  width:90%;
  padding: .3rem 2rem;
  vertical-align: middle;
}

.store-order .wallet-row.store-order{
  margin-right: 2rem !important;
}


.store-order .numbers p:first-child{
  font-size: 1.2rem;
  margin-bottom: 0rem !important;
}
.store-order p{
  font-size: 1rem;
}


.store-order .btn{
  background-color: #259D70 !important;
  font-size: .9rem;
  padding: .3rem 1rem;
  color: #fff;
  font-weight: 300;
}

.store-order .customer-table thead tr:first-child{
  border-bottom: 1px solid #DADADA;
}

.store-order .customer-table thead tr:last-child{
  border-bottom: 2px solid #DADADA;

}



/* .store-order .wallet-row{
  margin-right: 2rem;
} */
/* Store Order Page End */

/* coupion page start */


.coupion-content .form-group .form-control-file{
  padding: 5px 10px 32px 10px !important;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
}


.coupion-content .form-group input[type=file] {
  opacity: 1;
  position: absolute;
  top: 2rem;
}
.coupion-content .customer-filter, .coupion-content .wallet-row{
  background-color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18);
}

.coupion-content .customer-filter p{
  font-size: 1.2rem;
  color:#5e5e5e;
  margin-top:1rem;
  font-weight: 600;
}

.coupion-content .customer-filter .btn{
  background-color: blue !important;
  color:#fff;
  text-transform: uppercase !important;
  letter-spacing: 0.3em;
  font-weight: 400 !important;
}
.coupion-content label{
  font-weight: 600 !important;
}


.coupion-content .customer-coupon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.customer-coupon input{
  margin-right: 1rem;
  padding: .9rem;
}

.customer-coupon-button .btn {
  background-color: royalblue !important;
margin-right: 1rem;
text-transform: uppercase !important;
letter-spacing: .1em;
font-weight: 400 !important;
}

.coupion-content .customer-table .edit-back .btn{
  font-size:.8rem !important;
  background-color: #F0B935 !important;
  color:#fff;
  padding: .5rem;
  display: flex;
  text-transform: uppercase !important;
  font-weight: 400;

}

.coupion-content .bg-green{
  background-color: #3CCD5E;
  color:#fff;
}
.coupion-content .bg-danger,.coupion-content .bg-green{
padding: .2rem .5rem;
border-radius: 4px;
}

.coupion-content .customer-table .btn i{
  margin-right:.5rem;
}
.coupion-content .customer-table thead{
  border:1px solid #F8F9F9;
}

.coupion-content .customer-table tbody tr td{
  border:1px solid #F8F9F9;

}

.city-module .pagination{
  margin-top:3rem;
}


/* coupion page end */

/*  Comming Soon Start */

.comming-soon .ComingSoon{
  width:50%;
}

.comming-soon figcaption{
  font-size: 1rem;
  color: #5e5e5e;
  margin-top: .8rem;
}

.rdw-editor-wrapper{
  border:1px solid #DDDDDD !important;
}

.coupion-content .filter-search a .btn, .coupion-content .customer-table tbody tr td .btn{
  background-color: #5484F4 !important;
  
}

.coupion-content .customer-table thead th{
  padding:1rem 2rem;
} 
.coupion-content .btn:last-child{
  background-color: #3CCD5E !important;
  color:#fff;
  text-transform: uppercase !important;
  font-size: .9rem;
  font-weight: 400;
}

/*  Comming Soon end */

/*  Total Sale Start */
.sale-order{
  margin-top:1.5rem;
  margin-bottom: 2rem;
  
}

.total-sale-card{
  display: flex;
  position: absolute;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: left;
  padding:15px;
}

.total-sale-card img{
  margin-right:1rem
}

.total-sale-card h4{
  font-size:1rem;
  color:#5e5e5e !important;
  font-weight: 600;
  margin-bottom: 0;
}

.total-sale-card .big-text{
  font-size:1.2rem;
  font-weight: 300;
  margin-bottom: 0;
}

.total-sale-card .small-text{
  font-size: .75rem;
}


.total-sale-content{
  margin-top: -2rem;
}

.date-font{
  font-size:1rem;
}
.BlackCalendar{
  width:2.5%;
  margin-left: .5rem;
  margin-right: 2rem;
}

img.Add {
  width: 21px;
}

.customer-filter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  margin: 0 1rem;
}
.table-responsive {
    overflow: scroll !important;
    padding-bottom: 10px;
}
/*  Total Sale End */


/*  Marketing Store View */
.m-store-view .customer-details {
  padding: 0 2rem;
}
.m-store-view .customer-details .card{
  box-shadow: none;
}
.m-store-view .right-navbar p{
  margin-bottom: 0;
  text-align: right;
  font-size: .9rem;
  font-weight: 600;
}

.m-store-view .right-navbar p:last-child{
  font-weight: 400;
}

.m-store-view .filter-border-none {
  border-bottom: none;
  margin-bottom: 1rem;
}

.box {
  width: 300px;
  height: 50px;
  background-color: #2A88FA;
  clip-path: polygon(84% 0, 100% 50%, 84% 100%, 0 100%, 0 0);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  padding: 5px 0 0px 10px;
  position: relative;
  float: left;
  border-radius: 10px;
}

.box-white {
width: 300px;
height: 50px;
background-color: #f8f9fa;
clip-path: polygon(84% 0, 100% 50%, 84% 100%, 0 100%, 0 0);
box-shadow: 0 2px 5px 0 rgba(#f8f9fa);
color: #5e5e5e;
padding: 5px 0 0px 10px;
position: relative;
float: left;
border-radius: 10px;
}

.m-store-view-add .ant-steps-navigation .ant-steps-item::before {
  background-color: #fff;
  content: '';
}

.m-store-view-add .ant-steps-item-icon{
  display: none;
}

.m-store-view-add .card .card-body {
  margin: 0 1.5rem;
}

.m-store-view-add .form-group .form-control, .input-group .form-control, .m-store-view-add .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
padding:1rem 1rem;
background-color: #F3F7F6;
border:none;
}

.m-store-view-add .ant-select-arrow {
  margin-right:3rem
}

.m-store-view-add .form-control{
  border: none !important;
}

.m-store-view-add .input-group-undefined {
  background: #F3F7F6;
}
.m-store-view-add .i-grp-btn {
  height: 80%;
  border: none;
  margin: .3rem .4rem 0;
  width: 100px;
  border-radius: 0 5px 5px 0;
}

.m-store-view-add .i-grp-btn.camera-icon{
  width: 33px;
  background-color: #F3F7F6 !important;

}

.m-store-view-add .i-grp-btn.camera-icon i{
  font-size:2rem;
  color: #259D70;
}

.float-right.shop-location-map.input-group-undefined {
  transform: translate(-7px, 40px);
}

.m-store-view-add .card-user form .form-group {
  margin-bottom: 20px;
  margin-right: 1rem;
}

.m-store-view-add .btn-primary {
  background-color: #4b9e70 !important;
  
  text-transform: uppercase !important;
}

.m-store-view-add .customer-table{
  margin-top:1rem;
}

.m-store-view-add .customer-table thead tr th{
  border-bottom: 2px solid #dcdcdc;
  padding: 1.2rem 1rem;
}

.m-store-view-add .customer-table tbody tr td{
  padding: 1rem 1rem;
}

.m-store-view-add .filter-search p{
  margin-right:.5rem;
  font-size:1rem !important;
  text-transform: uppercase;
  color: #259D70;
  font-weight: 500;
}

/* .box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -50px;
  top: 0px;
  border-top: 20px solid transparent;
  border-right: none;
  border-left: 50px solid rgb(0, 0, 0, 0.1);
  border-bottom: 20px solid transparent;

} */

/* M store Add Product */
.right-navbar{
  width:40%;
}

.progress-bar-nav{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
align-items: center;
}

.progress-bar-nav{
  padding-top:1rem;
}
.progress-bar-nav div:first-child{
  padding-right: .5rem;
}

.progress-bar-nav div label:last-child{
  padding-left: .5rem;
  padding-top: .5rem;
  vertical-align: center;
}
.m-store-view .btn{
  text-transform: uppercase;
}

.border-secondary {
  border-color: #6c757d !important;
  padding: 0.5rem 2.5rem !important;
}

.m-store-view .select-option-storetype>div{
  margin-right: 10px;
  font-size: large;
}
.m-store-view .custom-control {
  width: 175px;
}

.m-store-view .card .card-body {
  margin: 0 6rem;
}
/* Marketing Store End  */

/* Target page Start */

.target-card h4{
  font-size:1.1rem;
  font-weight: 500;
  color: #5e5e5e;
}

.target-card .card{
box-shadow: none;
}

.target-card .sell{
  font-size: 1rem;
  font-weight: 600;
  color:#FFAE2B;
  margin-bottom: 0;
}
.target-card .addstore{
  font-size: 1rem;
  font-weight: 600;
  color:#B71FFE;
  margin-bottom: 0;
}
.target-card .addcustomer{
  font-size: 1rem;
  font-weight: 600;
  color:#3059FF;
  margin-bottom: 0;
}

.target-card .legend{
  text-align: center;
}



.sell-com{
  color:#FFAE2B !important;
}

.sell-reman{
  margin-left:.5rem;
  color: #FEE6C2 !important;
}

.addstore-com{
  color:#B71FFE !important;
}

.addstore-reman{
  margin-left:.5rem;
  color: #DABDFF !important;
}


.addcust-com{
  color:#3059FF !important;
}

.addcust-reman{
  margin-left:.5rem;
  color: #B8CCFF !important;
}
.target-card button{
  width:140px;
margin:0 auto;
font-weight: 400;
background-color: #259D70;
color: #fff;
padding: .5rem;
text-transform: uppercase !important;
}
.target-card button a {
  color: #fff !important
}

.target-card td p{
  font-size: 1rem;
  color: #5e5e5e;
  margin-bottom: 0;
}

.target-card a:hover {
  text-decoration: none !important;
}
.target-card td button{
  width:140px;
margin:0 auto;
font-weight: 400 !important;
background-color: #259D70;
color: #fff !important;
text-transform: uppercase !important;
font-size: .8rem !important;
padding: .5rem !important;
}
.target-card td{
  vertical-align: top !important;
}

.target-view-card h4{
  font-size: 1rem;
  color: #5e5e5e;
  padding:0 1rem;
}

.target-view-card p{
  font-size: .9rem;
  color: #5e5e5e;
  padding:0 0rem;

}

.target-view-card .row{
  padding-bottom: 2rem;
}

.target-view-card td img{

  width:35%;
}

.target-view-card th img{

  width:40%;
}

.target-view-details, .target-view-details1 {
  padding-top:.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

}

.target-view-details img{
  width:20%;
  padding-right: .7rem;
}

.target-view-details1 img{
  width:20%;
  padding-right: .7rem;
}

.target-view-details1 p:first-child, .target-view-details p:first-child{
  font-weight: 600;
}

.text-large{
  font-size: 1.3rem !important;
  color:#BBBBBB;
  letter-spacing: 0.03;
}

.target-view-card .row{
  margin: 0 auto;
  width:100%;
}

.target-view-card .customer-table thead tr th{
  border-bottom: 2px solid #dcdcdc;
  padding: 1.2rem 1rem;
}

.target-view-card .customer-table tbody tr td{
  padding: 1rem 1rem;
}

.left-space {
  margin-left: 10px;
  margin-right: 10px;
}
.row.target-view-history{
  margin: 0 auto;
  width: 100%;
  padding-bottom: 1rem;
}

.target-view-card h3{
  font-size: .9rem;
  font-weight: 600;
  color: #5e5e5e;
  text-align: center;
  margin-bottom: .3rem;
}

.target-history-details .content.target-view-card .table-responsive{
  text-align: left;
}

.table-responsive {
  overflow: scroll !important;
  padding-bottom: 10px;
}

.target-history-details .content.target-view-card {
  text-align: center;
}

button.load-more {
  margin-bottom: 1rem;
  background-color: transparent;
  border: none;
  color: #259D70
}
/* Target Page End */

/*  Add New Customer Page Start */

.add-new-customer .customer-filter{
  margin-bottom: 1.5rem;
}

.details-add-new-customer{
  padding: 1rem;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
}

.details-add-new-customer .form-group i{
  padding: 1rem;
  font-size: 1.5rem;

}

.details-add-new-customer .form-group i.fa-times-circle{
  color:#FFD2D2;
  fill: #757575;
}

.details-add-new-customer .form-group i.fa-plus-square{
color:#BEFEED;
}

.details-add-new-customer .form-group i.fa-check-square{
color:#259D70;
}

.details-add-new-customer button{
  background-color: #259D70;
text-transform: uppercase !important;
text-decoration: none;
font-weight: 400 !important;
padding: .5rem 2rem;
margin-top: 2rem;

}

.filter-search p:first-child{
  margin-right: 1rem;
} 

.customer-filter p{
  font-size: 1rem;
  font-weight: 500;
  color: #5e5e5e;
} 
.details-add-new-customer button:hover{
  background-color: #259D78 !important;

}
.details-add-new-customer .text-hiden{
  visibility: hidden;
}

.details-add-new-customer input{
  border:none !important;
  background-color: #F3F7F6;
}

/* Add New Customer Page End */


/* Order Page Start */

.order-details{
  background-color: #C2F2DC;
  padding: .5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.order-details p{
  font-size: .8rem;
  text-align: center;

  color:#7F8F87;
  font-weight: 600;
}

.order-details .order-list{
  color: #67C19D;
  font-weight: 600;
  font-size: 1rem;
}


.payment-details{
  padding: 2rem 4rem;
}

.payment-details .card{

display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem 1rem;
  margin-bottom: 0;
}

.payment-details p{
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 0;
}

.payment-details img{
  width: 70%;
}


.Process{
  color:#FE975F;
}
.Modifing{
  color:#86B5FE;
}
.Delivered{
  color:#30A176;
}
.Canceled{
  color:#DF5554;
}

.order-card .btn{

  background-color: #30A176 !important;
  color: #fff;
  font-size: .8rem;
  font-weight: 400;
  text-transform: capitalize !important;
  padding: .5rem 1rem;
}
.order-row{
  display: flex;
  justify-content: space-between;
  margin: .5rem 1rem;
}

.order-row p{
  font-size: 1rem;
  font-weight: 400;
  color: #5e5e5e;
}

.order-para{
  font-size: 1rem;
  font-weight: 400;
  color: #5e5e5e;
  margin-left: 5.8rem;
}
.order-left-navbar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.order-left-navbar p{
  margin-left: 1rem;
}

.order-right-navbar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.order-right-navbar p{
  margin-bottom: 0;
  text-align: right;
  margin-right: 2rem;
}

.order-view-details .customer-table{
  width: 80%;
  margin: 0 auto;
  border: none !important;
  box-shadow: none !important;
}

.order-view-details .customer-table tr td{
  border-top:none !important;
}


.order-view-details .btn{
  background-color: #259D70;
  text-transform: capitalize !important;
}

.order-view-details button.no-background.btn.btn-secondary {
  background-color: #fff;
  color: blue;
  margin:0 0 0rem 3rem;
  padding: 0;
}

/* Order Page End */


/*  Total Sale Page Start */

.total-sale-details{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.total-sale-details img{
  padding-right: 0rem;
  padding-top: .5rem;
  width: 70%;
}

.total-sale-details p{
  font-size: .8rem;
  margin-bottom: 0;
}

.total-sale-details p:first-child{
  font-weight: 600;
}
.total-sale-details p:last-child{
  font-size: .7rem;
}

.total-sale-details .text-large{
  font-size: 1.1rem !important;
}

.total-sale-para{
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0 !important;
  color: #5e5e5e;
}



.total-sals-details .customer-table thead tr th{
  border-bottom: 2px solid #dcdcdc;
  padding: 1rem 1rem;
}

.total-sals-details .customer-table tbody tr td{
  padding: 1rem 1rem !important;
}

.total-sals-details tr td img{
  width:50%;
}

.total-sals-details table{
  margin-top: 0;
}

.total-sals-details .recent-payment{
  font-size: 1rem;
  color:#5e5e5e;
  margin: 1rem 0rem;
  
}

/* Total Sale Page End */


@media only screen and (max-width: 1258px) {
  .getapplink-ml {
    margin-left: 10%;
}

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .target-view-card th img, .target-view-card td img {
    margin: 1rem 3rem 1rem 0rem;
    width: auto;
  }
  .target-view-card .row{
    margin: 0 auto;
    width:100%;
  }
  
  .payment-details {
    padding: 1rem 1rem;
}
  
  .navbar .navbar-toggler {
    width: 50px !important;
    height: 50px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
  }

  .footer-bdr-right {
    border-right: none !important;
  }
  .navbar-brand .mobile {
    display: block !important;
  }
  .navbar-brand .desktop {
    display: none !important;
  }
  img.img-fluid.desktop,
  .desktop {
    display: none;
  }

  img.img-fluid.mobile {
    display: block;
  }

  .row.text-center.desktop {
    display: none;
  }

  .row.text-center.mobile {
    display: block;
  }

  .container-fluid.okjii-mainbody.main-body-desktop {
    display: none;
  }
  .okjii-home .main-body-mobile {
    display: block !important;
  }
  section.okkji-faq.offer-mobile {
    display: block;
  }

  .get-applink {
    display: flex;
    width: 100% !important;
    vertical-align: middle;
  }

  .okkji-ourpractice .desktop {
    display: none !important;
  }
  .okkji-ourpractice .mobile {
    display: block !important;
  }

  .navbar-nav > li {
    padding-right: 15px;
  }

  nav#parallelogram {
    background-color: #fdfffe !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18) !important;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }
  .navbar-collapse {
    padding-top: 5px;
  }

  .okjii-explore {
    padding: 0 20px !important;
  }

  .home-testimonial {
    padding: 0 20px !important;
  }

  /* .figure-img2 {
  width: 100% !important;
  padding-right: 0;
  padding-left: 0;
  top: 50px;
  z-index: -1;
} */
  .okjii-home .getapp-link {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .about-getapplink {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .figure-img2 {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .retailer-figure-img2,
  .okjii-fast-img4,
  .faqimg01,
  .okjii-fast-ourpractice {
    top: 0px;
    z-index: -1;
  }

  .home-delivery-figure-img {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .figure-whayweshop img,
  .sell-online-figure-img img {
    width: 100% !important;
  }
  .home-delivery-figure-img,
  .okjii-figure-imgkhata,
  .okjii-create-store-img,
  figure.daily-figure-img {
    width: 100% !important;
  }

  .retailer-figure-img2 {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 24px !important;
    z-index: -1;
  }
  .okjii-offer-image {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 72px !important;
    z-index: -1;
  }

  .okjii-fast-img4,
  .faqimg01,
  .okjii-fast-ourpractice {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 56px !important;
    z-index: -1;
  }

  .okjii-install img {
    width: 11% !important;
    padding-top: 7px !important;
    padding-bottom: 1rem;
    align-content: center !important;
  }

  .carousel-control-next {
    top: 100% !important;
  }

  .carousel-control-prev {
    top: 100% !important;
  }

  .testmonial-slide.carousel-fade a.carousel-control-prev {
    width: 11%;
    height: 11%;
    padding-top: 5px;
    border-radius: 50%;
    transform: translatey(-50%);
  }

  .testmonial-slide.carousel-fade a.carousel-control-next {
    width: 11%;
    transform: translatey(-50%);
    height: 11%;
    left: 16% !important;
  }

  .container-fluid.footer-margin {
    margin: 0.5rem 0 0.5rem 0;
  }

  .promoting-card.pt-5 {
    padding-top: 0rem !important;
    margin-top: -1rem !important;
  }

  img.rounded-circle.testimoni-img {
    margin-left: 0 !important;
    /* margin-right: 20px !important; */
  }

  .container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }

  .install-now,
  p.install-now {
    margin-top: 0.5rem !important;
    font-size: 1.2rem !important;
    align-items: center !important;
    margin-bottom: 0 !important;
    font-weight: 700 !important;
  }
  .contact-form {
    padding-left: 0rem;
  }
  .okjii-explore h1 {
    font-weight: 600;
    color: #5e5e5e !important;
    font-size: 18pt;
    padding-top: 3.5rem !important;
  }

  .okjii-explore h2 {
    padding-bottom: 1rem !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    color: #5e5e5e !important;
    letter-spacing: 0.3px;
  }

  .okjii-retaler h1 {
    font-size: 18pt !important;
    padding-bottom: 1rem;
    padding-top: 0rem;
    text-align: center;
  }

  .accordion .accordion-item .title {
    padding: 8px !important;
  }

  .okjii-create-store-background {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .okjii-create-store h1 {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem;
  }

  .okjii-sell-online {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .okjii-daily h1 {
    padding-top: 2rem !important;
    color: #2a2aff !important;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    padding-bottom: 1rem;
  }

  figure.home-delivery-figure-img.my-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .okjii-khatabook1 h1 {
    padding-top: 0.5rem !important;
  }

  .home-testimonial h1 {
    padding-top: 2rem !important;
  }

  .owl-carousel .owl-item {
    left: 2rem;
  }

  footer.page-footer h5 {
    padding-top: 1rem !important;
    font-size: 1.1rem !important;
  }

  .okjii-fastcon {
    position: absolute;
    top: 50px;
    left: 20px;
  }

  .okjii-fastcon h1 {
    font-size: 14pt !important;
    font-weight: 600;
    color: #fff;

    margin-top: 0rem;
    padding-bottom: 0 !important;
  }

  .okjii-fastcon h2 {
    font-size: 1rem !important;
    font-weight: 400;
  }

  .okjii-fasthow h1 {
    font-weight: 500;
    color: #5e5e5e !important;
    font-size: 18pt;
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .okjii-fasthow {
    padding-left: 1rem !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.18) !important;
  }

  .okjii-fastfast h1 {
    font-weight: 500 !important;
    color: #5e5e5e !important;
    font-size: 18pt !important;
    text-align: center;
    padding-top: 30px !important;
  }

  .offer-page {
    position: absolute;
    width: 100%;
    top: 0%;
    display: flex;
    justify-content: right !important;
  }

  .our-story h1 {
    padding-bottom: 0rem;
  }

  .search-page {
    position: absolute;
    width: 100%;
    top: 33%;
    display: flex;
    justify-content: center !important;
    left: 0rem;
    right: 0rem;
  }

  .faqimg05,
  .faqimg07 {
    width: 25% !important;
  }
  .faq-h1 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .support-para {
    font-size: 0.85rem !important;
    font-weight: 400 !important;
  }
  .okkji-faqs .h1-trusted {
    padding-top: 0rem !important;
  }

  .okjii-offer h1 {
    font-size: 18pt !important;
    font-weight: 500 !important;
    color: #5e5e5e;
    padding-top: 0rem !important;
    padding-left: 0;
  }
  .okjii-offer h2 {
    padding-left: 0;
  }

  .pt-5,
  .py-5 {
    padding-top: 1rem !important;
  }

  .input-form {
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .btn-offerpage {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
  }

  .okkji-about h1 {
    color: #5e5e5e !important;
    font-weight: 400;
    font-size: 10pt;
    padding-top: 1rem !important;
  }

  .okjii-digital {
    left: 15px;
  }

  .okkji-ourpractice .ourpractice {
    padding-top: 1rem;
  }

  .google-top {
    margin-top: -2rem;
  }

  .our-team .Mannu {
    width: 33%;
  }
  .img-apple {
    padding-top: 2rem;
    width: 52%;
  }

  .OkjiiRetailerBody-mobile h1 {
    padding-top: 0rem;
    padding-bottom: 2rem;
    font-size: 18pt;
    color: #5e5e5e;
    font-weight: 600;
  }
  .OkjiiRetailerBody-mobile p {
    padding-bottom: 3rem;
  }
  .container-fluid.OkjiiRetailerBody {
    display: none;
  }
  .container-fluid.OkjiiRetailerBody-mobile {
    display: block;
  }
  section.okkji-faq.faq-mobile,
  .offer-mobile {
    display: block;
  }
  section.okkji-faq.faq-desktop,
  .offer-desktop {
    display: none;
  }

  .OkjiiRetailerBody-mobile {
    background-image: url(../src/img/retailer/Retailer.png);

    top: 50px;
    left: 0;
    right: 0;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
  }
  .row-addding {
    padding-top: 3rem;
  }

  .okkji-howwework {
    margin-top: -5rem;
    background-color: #fff !important;
  }

  .search-page h1 {
    font-size: 14pt;
    font-weight: 700;
    color: #4065ba;
    margin-top: -2rem !important;
    margin-left: -1rem !important;
    padding-bottom: 1rem;
  }

  .offerheading {
    top: -4% !important;
  }
  .offerheading {
    margin-left: -1rem !important;
  }

  .offerheading h1 {
    font-size: 12pt;
    padding-top: 2rem;
    padding-left: 1rem !important;
    padding-right: 2rem;
    padding-bottom: 0.5rem !important;
  }
  .offerheading h2 {
    font-size: 14px !important;
    font-weight: 500;
    color: #5e5e5e;
    padding-left: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .faq-mobile .input-form {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0.5rem;
    text-align: center !important;
    width: 100% !important;
  }

  .okjii-bring {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    padding-top: 0rem;
    padding-bottom: 1.5rem;
    color: #5e5e5e;
  }

  .offer-mobile .search-form {
    padding-top: 0rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.18) !important;
  }

  .okkjiicontactus-mobile {
    padding-top: 13rem !important;
    background-image: url(../src/img/contact/Contactusmobile.png);
    top: 0;
    left: 0;
    right: 0;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
  }

  .okkjiicontactus-desktop {
    display: none;
  }
  .okkjiicontactus-mobile {
    display: block;
  }

  .okkjiicontactus-padding {
    margin-top: 0rem;
  }
  .okjii-term-condition {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  h1.term-h1-heading {
    padding-left: 15px !important;
  }

  /* Okkji Blog */
  .related-blog h1 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .blog-column {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .okjii-blog-h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 700;
  }

  .container-fluid.blog-information {
    padding-left: 15px;
    padding-right: 15px;
  }

  .blog-information img {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    vertical-align: middle;
  }

  .leave-reply-box {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .leave-reply-outer {
    width: 100% !important;
  }
  .star {
    border: 1px solid #bdbdbd;
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem;
  }

  input#formGroupExampleInput {
    margin-right: 0.5rem !important;
  }

  .blog-column .btn:hover {
    background-color: #fff !important;
    color: blue !important;
  }
  /* Pre Registrations */
  .okjii-regbody h1 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #5e5e5e;
    padding-top: 0.7rem;
    padding-bottom: 1rem;
  }

  h1.okjii-reg-h1 {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }

  .okjii-reg-h1 {
    top: 0rem;
    left: 1rem;
  }

  .pre-okjii {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
  }
  .okjii-registration input {
    padding-left: 1rem !important;
    padding-right: 5rem !important;
  }
  .okjii-registration form {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .pre-regbody .h1-trusted {
    padding-top: 0rem !important;
    margin-top: -3rem;
  }

  .accordion {
    margin: 5px;
  }
  .faq-card {
    margin-left: 5px;
    margin-right: 5px;
  }

  .reg-offerpage img {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }
  .form-table {
    width: auto;
    display: block;
    align-items: center;
    justify-content: center;
  }

  /*  career css start */

  .career-left {
    top: 2rem;
    left: 1rem;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #5e5e5e;
    width: 50%;
  }
  .carrier-blog img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .carrier-blog {
    padding-left: 5px;
    padding-right: 5px;
  }

  .career-offer h1 {
    margin-left: -2rem;
    margin-bottom: 1.5rem;
    margin-top: -2rem;
  }
  .career-card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #5e5e5e;
    width: 100% !important;
    padding: 5px;
    margin-bottom: 0.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  /*  career css end */

  /* career page start */

  .career-page-left {
    width: 75%;
  }

  .career-page-left {
    top: 2rem;
    left: 1rem;
  }

  .career-page-left h1 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .career-page h1.text-center {
    padding-top: 1rem;
  }

  .career-technical {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .career-technical ul {
    padding-left: 2rem;
    padding-right: 1rem;
  }

  /* career page end */
  /*  Refere earn-page start */

  .refer-earn-desktop {
    display: none;
  }

  .refer-earn-mobile {
    display: block;
  }

  .container.refer-earn-form-desktop {
    display: none;
  }

  .container.refer-earn-form-mobile {
    display: block;
  }

  .desktop-login{
    display: none;
  }

  .mobile-login{
    display: block;
  }

  .refer-earn-con h1 {
    font-size: 17pt;
    font-weight: 600;
    color: #5e5e5e;
    padding-top: 0rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .refer-earn-content {
    position: absolute;
    top: 6rem;
    left: 1.3rem;
  }

  .refer-earn-mobile img.Mobilebackground {
    padding-top: 4rem;
    padding-bottom: 7rem;
  }

  .refer-earn-apple {
    padding-top: 16rem;
    width: 100%;
  }

  .refer-earn-apple .Asset7-install {
    padding-top: 3rem;
    margin-top: -1rem !important;
  }

  .okjii-referearn {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .refer-term-condition h1,
  .refer-term-condition p {
    margin-left: 15px;
    margin-right: 15px;
  }

  /* selfy start */

  .selfi-blog {
    display: flex;
    justify-content: center;
    min-height: auto !important;
    margin-left: 0rem;
  }
  .selfi-blog {
    position: relative;
    height: auto;
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
  }

  .selfi-blog .right1,
  .selfi-blog .left1 {
    border: 1px solid #259d70;
    width: 150px;
    height: 165px;
  }
  .selfi-blog {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .selfi-blog p {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.5rem;
    text-align: center;
    font-weight: 600;
    color: #259d70;
  }

  .selfi-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .selfi-blogicon {
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-top: -1.5rem;
  }

  .selfi-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #259d70;
    padding: 5px;
  }

  .selfi-blog button {
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: #5e5e5e;
    padding-top: 5rem;
  }

  /*  Selfy End */

  /* .refer-earn-mobile{
  background-image: linear-gradient(to right, rgb(255, 255, 255, 0.30), rgb(37, 157, 112, 0.1)), url(/static/media/BackgroundMobile.26271e97.png);
    top: 50px !important;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
} */

  /*refer earn page end */

/*  Admin Panels Mobile View */

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 0px) !important;
  background-color: #fdfeff;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

nav.navbar-absolute.fixed-top.navbar.navbar-expand-lg.bg-dark {
  background-color: #eeeeee !important;
}

nav.navbar-absolute.fixed-top.navbar.navbar-expand-lg.bg-dark {
  z-index: 9999 !important;
}

li.nav-btn.nav-item {
  display: inline-flex;
  justify-content: left;
}

a.nav-link.nav-btn i{
  padding-bottom: 1rem;
}

.sidebar {
  width: 220px !important;
}
.sidebar .sidebar-wrapper {
  width: 220px !important;
}

a.nav-link.nav-btn i:last-child{
  padding-bottom: 0;
}

/* Login Page Start */

.login-page .footer {
  padding: 24px 0;
  margin-top: 1rem;
}
.login-information .btn {
  padding: 0.7rem 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
img.img-fluid.Logowhite-bg {
  transform: translate(100%, -66px);
  width: 30%;
}
.login-information p{
  font-size: .9rem;
}
.login-page-right {
background-color: #fff;
margin-top: 5rem !important;
padding-top: 15px;
border-radius: 15px;
margin-left: 20px;
}
.login-information {
  padding-top: 15px;
  margin: 0 auto;
}
.logo-white-login{
  margin-top: 0px !important;
}

.login-information h1 {
  padding: 1rem 0rem;
}

.login-page-right {
  position: absolute;
  top: 8px;
   right: 20px;
   left: 0px;
}
.login-information p{
  margin-bottom: 1rem;
}

.main-panel > .content {
  min-height: calc(100vh - 272px) !important;
}
.main-panel > .content {
  padding: 0px 5px !important;
  margin-top: 85px !important;
}
.login-page-information .mobile-login{
  margin-top:-1.7rem !important;
}

.filter-search {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left !important;
}

/*  Login Page End */


/* Dashboard page start */

.dashbord-card1, .dashbord-card2, .dashbord-card3, .dashbord-card4 {
  height:160px;
  margin: 0px;
}

.card-list.row {
  display: flex;
  flex-wrap: nowrap;
}

/*  Dashboard page end */
.customer-filter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 1rem;
}
.store-page table{
  margin: 0 1rem;
}

.store-page table thead th{
  margin: 1rem 1rem;
}

.store-page table tbody td{
  margin: 1rem 1rem;
}


}
/* @media screen and (max-width: 991px){ */
.navbar {
  padding: 16px;
}
/* } */

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 768px) and (min-width: 601px) {
  /* .figure-img2 {
    padding-right: 0 !important;
    padding-left: -1px !important;
    top: 0px;
    z-index: -1;
  
  } */
/*Admin Panal Css Start*/

.okkjii-customer-mt {
  margin-top: 0%;
}
.okjii-fastcon h1 {
    font-size: 10pt !important;
    font-weight: 600;
    color: #fff;
    padding-top: 1.5rem;
    line-height: 1.8rem;
}
.okjii-fastcon {
    position: absolute;
    top: 55px;
    left: 30px;
}
.RetailerHome {
  width: 50% !important;
  margin-top: 0rem;
}
.home-order-details {
    left: 20%;
    top: -280px;
    position: relative !important;
}
.order-list-items {
    position: inherit;
    top: 10px;
    background: #fff;
    padding: 5px 0px 5px 10px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px #DCDCDC;
}
.order-list-items p {
    margin-top: 16px;
    font-size: 14px !important;
    margin-left: 20px;
}
.order-list-items .pright {
    text-align: center;
    margin-left: 80px;
}
.recent-order-list {
    position: inherit;
    top: 30px;
}
li.nav-btn.nav-item {
  display: inline-flex;
  justify-content: left;
}

.nav-open .main-panel  {
  position: relative;
  float: right;
  width: calc(100% - 215px) !important;
  background-color: #fdfeff;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  z-index: 9999;
}

.main-panel  {
  position: fixed;
  float: right;
  width: calc(100% - 0px) !important;
  background-color: #fdfeff;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  z-index: 9999;
}


nav.navbar-absolute.fixed-top.navbar.navbar-expand-lg.bg-dark {
  background-color: #eeeeee !important;
}

nav.navbar-absolute.fixed-top.navbar.navbar-expand-lg.bg-dark {
  z-index: 9999 !important;
}

a.nav-link.nav-btn {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.dashboard-card-icon {
  max-width: 31% !important;
}

a.nav-link.nav-btn i{
  padding-bottom: 1rem;
}
img.img-fluid.Logowhite-bg {
  transform: translate(-410px, 15px);
  width: 32%;
}

.nav-open img.img-fluid.Logowhite-bg {
  transform: translate(-250px, 15px);
  width: 32%;
}



.nav-open .main-panel {
    right: 0;
     transform: translate3d(0px, 0, 0) !important;
}

/* Admin Panel Css End */


  .container-fluid.okjii-mainbody.main-body-desktop {
    display: none;
  }
  .okjii-home .main-body-mobile {
    display: block !important;
    margin-top: 24px;
  }

  .retailer-figure-img2,
  .okjii-fast-img4,
  .faqimg01,
  .okjii-offer-image,
  .okjii-fast-ourpractice {
    top: 0px;
    z-index: -1;
  }

  .about-getapplink {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: 100% !important;
  }

  .carousel-item.active.carousel-slide-item.turested-slide {
    display: flex !important;
    align-content: center;
    justify-content: space-around;
    width: 90%;
  }
  .turested-slide img.twentyfour,
  .turested-slide img.vmart {
    height: 50px !important;
  }
/*  Login Page Css*/

.nav-open .login-information {
  padding-top: 0rem;
  margin: 0 auto;
  width: 63%;
  transform: translateX(77px);
}

.login-information {
  padding-top: 0rem;
  margin: 0 auto;
  width: 75%;
  transform: translateX(50px);
}
.login-information .btn {
  padding: 0.7rem 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.main-panel > .content {
  min-height: calc(100vh - 644px) !important;
}

}
@media screen and (max-width: 1440px) {
  .marketing-iframe {
    width: 350px;
    height: 300px;
}
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .marketing-iframe {
    width: inherit;
    height: 200px;
}
.store-page table {
    margin: 0 0.5rem;
}
.OrderListing-img {
  width: 35px;
}
.card .card-body {
    padding: 15px 0px 0px 0px !important;
}
.target-card h4 {
    font-size: 15px;
    font-weight: 500;
    color: #5e5e5e;
}
.okjii-dashboard h5 {
    color: #5e5e5e;
    font-size: 18px;
}
.customer-filter p span {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 500;
    margin-left: 10px;
}
.target-view-card h4 {
    font-size: 14px;
    color: #5e5e5e;
    padding: 0 1rem;
}

  .home-order-details {
    left: 15px;
    top: -225px;
    position: relative !important;
}
.navbar .navbar-brand {
    font-size: 14px !important;
    margin-right: 0;
}
.customer-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0rem !important;
    margin-bottom: 10px !important;
    padding: 0px 5px;

    margin-bottom: 0.8rem !important;
}
.marketing-dashbord .Acceptorder {
    width: 65%;
}
.marketing-dashbord .Target {
    width: 65%;
}
.customer-filter .filter-txt {
    color: #259D70;
    padding-right: 1rem;
    padding-left: 0rem !important;
}
.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
}
.m-store-view-add .card .card-body {
  margin: 0;
  width: 340px !important;

  width: auto !important;
  padding: 15px 15px 15px 0px !important;
}
.ant-steps-navigation > .ant-steps-item::after {
    display: none !important;
}
.nav-open .main-panel {
    right: 0;
    transform: translate3d(220px, 0, 0) !important;
}
.filter-search {
  display: contents !important;
  flex-wrap: nowrap;
}
.customer-filter p {
    font-size: 15px !important;
    color: #5e5e5e;
    margin-bottom: 15px !important;
}
.df {
  margin: 0 auto !important;
  margin-left: 0 !important
}
.main-panel .ml-2, .mx-2 {
  margin-left: 35% !important
}

.navbar .container-fluid {
    padding-right: 15px;
    padding-left: 0px !important;
}
.about-mobile-view-image  {
  display: initial;
}
.about-mobile-view-image1 {
  display: none;
}
.okkji-howwework {
  box-shadow: none;
}
.okjii-explore p {
    color: #5e5e5e;
    font-size: 13px;
    letter-spacing: 0.5px;
    padding-bottom: 1rem;
}
.about p {
    font-size: 1rem;
    color: #5e5e5e!important;
    padding-top: .2rem;
    text-align: justify;
    padding: 0px 10px;
}

.okjii-fast-img4, .faqimg01, .okjii-fast-ourpractice {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 56px !important;
    z-index: -1;
    height: auto;
}
.okkjii-customer-mt {
  margin-top: 0%;
}
.okjii-fastcon h1 {
    font-size: 10pt !important;
    font-weight: 1000;
    color: #fff;
    margin-top: 0rem;
    padding-bottom: 0 !important;
    line-height: 1.2rem;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: center;
}
.order-list-items .pright {
    text-align: right;
    margin-left: 65px;
    margin-top: -5%;
}
.okjii-explore-left {
    margin-left: 5%;
    margin-top: -16px;
}
.okjii-mainbody-mobile {
    top: 40px !important;
}
.okjii-explore {
    margin-left: 0%!important;
}
.h1, h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 5px !important;
}
.okjii-home .getapp-link {
    padding: 0px 0 0 0;
}
.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto;
}
.okjii-explore h2 {
    padding-bottom: 0rem!important;
    font-size: 1.2rem!important;
    font-weight: 400!important;
    color: #5e5e5e!important;
    letter-spacing: .3px;
}
.okjii-home .getapp-link {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
}
.navbar {
    padding: 5px !important;
}
.main-panel > .content {
    padding: 0px 3px !important;
    margin-top: 65px !important;
}
.marketing-dashbord .Target {
    width: 60%;
}
.marketing-dashbord .Acceptorder {
    width: 45%;
}
  }
@media only screen and (min-width: 1024px) {
  .navbar-nav > li {
    list-style-type: none;
    padding-right: 1.5rem;
    margin: 0;
    flex-direction: row;
    justify-content: space-around;
    font-size: 0.85rem !important;
    font-weight: 300px !important;
    flex-wrap: nowrap;
    word-break: normal;
    white-space: nowrap;
}
.navbar .navbar-nav .nav-item:first-child {
    margin-top: 0px; 
}
}
@media screen and (max-width: 320px) {
.m-store-view-add .card .card-body {
    margin: 0;
    width: 300px !important;
    padding: 15px 15px 15px 0px !important;
}
.ant-steps-navigation {
    padding-top: 12px;
    padding-left: 20px !important;
}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (min-width: 800px) {
  /* .figure-img2 {
  padding-right: 0 !important;
  padding-left: 3% !important;
} */
/* Dashboard page start */
.navbar .navbar-nav .nav-item:first-child {
    margin-top: 0px; 
}
.dashbord-card1, .dashbord-card2, .dashbord-card3, .dashbord-card4 {
  height:160px;
}

.card-list.row {
  display: flex;
  flex-wrap: nowrap;
}


.navbar-nav > li {
    list-style-type: none;
    padding-right: 1.0rem;
    margin: 0;
    flex-direction: row;
    justify-content: space-around;
    font-size: 0.85rem !important;
    font-weight: 300px !important;
    flex-wrap: nowrap;
    word-break: normal;
    white-space: nowrap;
}

  .retailer-figure-img2,
  .okjii-fast-img4,
  .faqimg01,
  .okjii-offer-image,
  .okjii-fast-ourpractice {
    margin-top: 51px;
    top: 0px;
    z-index: -1;
  }
  .main-panel > .content {
    min-height: calc(100vh - 123px) !important;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    top: 100% !important;
  }

  .carousel-item.active.carousel-slide-item.turested-slide {
    display: flex !important;
    align-content: center;
    justify-content: space-around;
    width: 80%;
  }
  .turested-slide img.twentyfour,
  .turested-slide img.vmart {
    height: 50px !important;
  }
  .about-getapplink {
    margin-left: 19rem;
    margin-right: 19rem;
  }
  .login-information {
    padding-top: 6rem;
    margin: 0 auto;
}
.login-information .btn {
  padding: 0.7rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.login-information .main-panel > .content {
  min-height: calc(100vh - 353px);
 
}
img.img-fluid.Logowhite-bg {
  transform: translate(-350px, -60px);
  width: 32%;
}

}
@media only screen and (min-width: 768px) {


  .main-panel > .content {
    padding: 0px 20px !important;
    margin-top: 75px !important;
    min-height: 75vh;
}
.navbar-nav > li {
    list-style-type: none;
    padding-right: 0rem;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-around;
    font-size: 0.85rem !important;
    font-weight: 300px !important;
    flex-wrap: nowrap;
    word-break: normal;
    white-space: nowrap;
}
.navbar .navbar-nav .nav-item:first-child {
    margin-top: 0px !important; 
}
.main-panel {
    position: fixed;
    float: right;
    width: calc(100% - 260px);
    background-color: #ffffff;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .img.img-fluid.Logowhite-bg{
    transform: translate(-1372px, -60px) !important;
  }
  .carousel-control-next,
  .carousel-control-prev {
    top: 100% !important;
  }

  /* .figure-img2 {
  padding-right: 0 !important;
  padding-left: 0% !important;
} */

  .carousel-item.active.carousel-slide-item.turested-slide {
    display: flex !important;
    align-content: center;
    justify-content: space-around;
    width: 90%;
  }
  .turested-slide img.easyday,
  .turested-slide img.vishal {
    height: 52px !important;
  }
  .login-information {
    padding-top: 6rem;
    margin: 0 auto;
}
.login-information .btn {
  padding: 0.7rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

img.img-fluid.Logowhite-bg {
  transform: translate(-660px, -60px);
  width: 32%;
}

}

.offer-page {
  margin-top: -39rem;
  margin-left: 2rem !important;
}

.offer-page h1 {
  font-size: 17pt;
  font-weight: 600;
  color: #5e5e5e;
}

.offerheading {
  position: absolute !important;
  width: 100%;
  top: 15%;
  display: flex;
}

.offerheading h1 {
  font-size: 17pt;
  font-weight: 600;
  color: #5e5e5e;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.offerheading h2 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #5e5e5e;
  padding-left: 2rem;
  padding-bottom: 2rem;
}
.offerheading .input-form {
  padding-left: 2rem;
  padding-right: 2rem;
}
.offer-wrapper {
  display: flex;
  margin: 50px 0px;
  justify-content: space-around;
  /* background-color: #000; */
  width: 100%;
  padding: 10px;
}
.offer-title {
  color: #259d70;
  text-transform: capitalize;
  font-size: 23px;
}
.offer-image {
  width: 250px;
  margin: 0px 10px;
}

.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.lsn {
  list-style: none;
}
.df {
  display: flex;
  margin: 0 auto !important;
}
.aic {
  align-items: center;
}
.mtb-10 {
  margin: 10px 0px;
}

.fdc {
  flex-direction: column;
}

.okkji-howwework {
  display: flex;
  flex-direction: column;
}
